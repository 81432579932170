import React from "react";
import { ActionIcon, CopyButton, Flex, rem, Tooltip } from "@mantine/core";

import { IconCheck, IconCopy } from "@tabler/icons-react";
import { formatHexStr } from "../formatHexStr";
import {
  formatTimeStamp,
  LONG_DISPLAY_FORMAT,
} from "../collectionFound/dateTimeUtils";
import LogoVBSvg from "../../svg/LogoVBSvg";
import LogoBlockchainSvg from "../../svg/LogoBlockchainSvg";
import DownloadPDFUserFound from "../../components/DownloadPDFUserFound";
import { formatCollectionUserFound } from "../formatCollectionUserFound";
import { parseDate } from "../collectionFound/resultScreen";

// Function for creating data
export const stampHistoryTableData = (
  filteredItems,
  staticBasePath,
  userTimezone,
  handleClickFilterredbyCollection,
) => {
  if (!Array.isArray(filteredItems)) {
    return [];
  }

  return filteredItems.map((item) => ({
    objectCid: (
      <Flex align="center" gap={2}>
        {item?.objectCid ? formatHexStr(item?.objectCid, 6, 4, 13) : "-"}
        <CopyButton value={item.objectCid} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? "Copied" : "Copy"}
              withArrow
              position="right"
            >
              <ActionIcon
                color={copied ? "teal" : "gray"}
                variant="subtle"
                onClick={copy}
              >
                {copied ? (
                  <IconCheck style={{ width: rem(16) }} />
                ) : (
                  <IconCopy style={{ width: rem(16) }} />
                )}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Flex>
    ),
    timestamp: formatTimeStamp(
      item?.timestamp,
      userTimezone,
      LONG_DISPLAY_FORMAT,
    ),
    blockchain_receipts: (
      <Flex align="center" justify="center" gap={10}>
        {item?.objectCid && (
          <a
            title="vBase Stamp Verification"
            href={`/verify/?cid=${item?.objectCid}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoVBSvg />
          </a>
        )}
        {item?.transactionHash && (
          <a
            title="Public Blockchain Record"
            href={`${item.blockExplorerUrl}${item?.transactionHash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LogoBlockchainSvg />
          </a>
        )}
        <DownloadPDFUserFound
          user_id={item.user_id}
          user={item.user}
          collection_name={item.collection_name}
          collection_hash={item.collection_hash}
          objectCid={item.objectCid}
          blockExplorerUrl={item.blockExplorerUrl}
          transactionHash={item.transactionHash}
          chainId={item.chainId}
          blockchainName={item.blockchainName}
          formattedDateTime={
            formatTimeStamp(item.timestamp, userTimezone, LONG_DISPLAY_FORMAT) +
            " " +
            userTimezone
          }
        />
      </Flex>
    ),
    collection_name: (
      <Flex
        align="center"
        gap={2}
        title={
          formatCollectionUserFound(item?.collection_name).title_hover &&
          item?.collection_name
            ? item?.collection_name
            : ""
        }
        onClick={() =>
          handleClickFilterredbyCollection(
            formatCollectionUserFound(
              item.collection_name && item.collection_name !== "n/a"
                ? item.collection_name
                : item.collection_hash && item.collection_hash !== "n/a"
                ? formatHexStr(item.collection_hash, 6, 4, 13)
                : "<<None>>",
            ).formatted,
          )
        }
      >
        {item?.collection_name && item?.collection_name !== "n/a" ? (
          <>
            {formatCollectionUserFound(item?.collection_name).formatted}

            <CopyButton value={item.collection_name} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copied" : "Copy"}
                  withArrow
                  position="right"
                >
                  <ActionIcon
                    color={copied ? "teal" : "gray"}
                    variant="subtle"
                    onClick={(event) => {
                      event.stopPropagation();
                      copy();
                    }}
                  >
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </>
        ) : item?.collection_hash && item?.collection_hash !== "n/a" ? (
          "<<Not Available>>"
        ) : (
          "<<None>>"
        )}
      </Flex>
    ),
    collection_name_sort: item?.collection_name,
    cid: (
      <Flex
        align="center"
        gap={2}
        onClick={() =>
          handleClickFilterredbyCollection(
            item.collection_hash !== "n/a"
              ? item.collection_name !== "n/a" && item.collection_name
                ? formatCollectionUserFound(item.collection_name).formatted
                : formatHexStr(item.collection_hash, 6, 4, 13)
              : "<<None>>",
          )
        }
      >
        {item?.collection_hash !== "n/a" && item?.collection_hash ? (
          <>
            {formatHexStr(item?.collection_hash, 6, 4, 13)}

            <CopyButton value={item?.collection_hash} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copied" : "Copy"}
                  withArrow
                  position="right"
                >
                  <ActionIcon
                    color={copied ? "teal" : "gray"}
                    variant="subtle"
                    onClick={(event) => {
                      event.stopPropagation();
                      copy();
                    }}
                  >
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </>
        ) : (
          "<<None>>"
        )}
      </Flex>
    ),
    cid_sort: item.collection_hash,
  }));
};

// Function for creating columns
export const stampHistoryTableColumns = () => [
  {
    accessorKey: "objectCid",
    header: "Content ID",
    enableSorting: true,
    size: 70,
  },
  {
    accessorKey: "timestamp",
    header: "Timestamp",
    size: 200,
    sortingFn: (rowA, rowB) => {
      const timestampA = parseDate(rowA.original.timestamp);
      const timestampB = parseDate(rowB.original.timestamp);
      return timestampA - timestampB;
    },
  },
  {
    accessorKey: "blockchain_receipts",
    header: "Receipt Links",
    enableSorting: false,
    size: 120,
    mantineTableBodyCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "collection_name",
    header: "Collection Name",
    enableSorting: true,
    size: 200,
    sortingFn: (rowA, rowB) => {
      const collectionNameA = rowA.original.collection_name_sort || "";
      const collectionNameB = rowB.original.collection_name_sort || "";

      if (collectionNameA === collectionNameB) return 0;
      if (collectionNameA === "n/a") return 1;
      if (collectionNameB === "n/a") return -1;

      return collectionNameA.localeCompare(collectionNameB);
    },
  },
  {
    accessorKey: "cid",
    header: "Collection ID",
    size: 120,
    sortingFn: (rowA, rowB) => {
      const cidA = rowA.original.cid_sort || "";
      const cidB = rowB.original.cid_sort || "";

      if (cidA === cidB) return 0;
      if (cidA === "n/a") return 1;
      if (cidB === "n/a") return -1;

      return cidA.localeCompare(cidB);
    },
  },
];

// Function for configuring the table
export const stampHistoryTableOptions = (columns, data, faIcons, theme) => ({
  columns,
  data,
  icons: faIcons,
  paginationDisplayMode: "pages",
  enableFullScreenToggle: false,
  enableTopToolbar: false,
  enableBottomToolbar: true,
  enableColumnActions: false,
  initialState: { pagination: { pageIndex: 0, pageSize: 20 } },
  withColumnBorders: true,
  mantinePaginationProps: () => ({
    showRowsPerPage: false,
    color: theme.customBackgrounds.table,
    autoContrast: true,
    style: {
      paddingBottom: "1.75em",
    },
  }),
  mantineTableProps: {
    style: {
      border: "none",
      borderCollapse: "collapse",
    },
    className: "html-table-mantine",
  },
  mantinePaperProps: {
    withBorder: false,
    shadow: "none",
  },
  mantineTableBodyCellProps: {
    style: {
      padding: "0.6875em 0.9375em 0.5625em 0.9375em",
      fontSize: "1.142em",
    },
  },
  mantineTableHeadCellProps: {
    style: {
      padding: "0.55em 0.9375em 0.4375em 0.9375em",
      fontSize: "1.142em",
      fontFamily: theme.headings.fontFamily.bold,
    },
  },
  mantineTableBodyRowProps: () => ({
    style: {
      borderBottom: theme.customColors.tableBorder,
    },
  }),
  mantineTableHeadRowProps: () => ({
    style: {
      backgroundColor: theme.customBackgrounds.table,
    },
  }),
});

import { URLs } from "./apis";
export const verifyHashWorkerVerify = async (
  hash,
  supportedHashAlgoMap,
  setError,
  setStampData,
  setCurrentScreen,
  setObjectHash,
  setLoading,
) => {
  const url = new URL(window.location);
  url.searchParams.set("cid", hash);
  window.history.pushState({}, "", url);
  // Check if hash exists before adding
  supportedHashAlgoMap ||= {};
  if (!(hash in supportedHashAlgoMap)) {
    supportedHashAlgoMap[hash] = hash;
  }
  // Get list of hashes
  const objectHashes = Object.keys(supportedHashAlgoMap || {});

  const requestData = {
    "object-hashes": objectHashes,
  };

  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  try {
    setLoading(true);
    const response = await fetch(URLs.VERUFY_API_URL, {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(requestData),
    });
    const data = await response.json();

    if (data.stamp_list) {
      const firstElement = {
        ...data.stamp_list[0],
        display_timezone: data.display_timezone,
      };
      setStampData(firstElement);
      setCurrentScreen("found");
      setError(null);
    } else {
      setStampData(null);
      setObjectHash(hash);
      setCurrentScreen("notfound");
      setError(null);
    }
    setLoading(false);
  } catch (error) {
    console.error("Error:", error);
    setError("An error occurred while verifying the stamp.");
    setLoading(false);
  }
};

import { formatCollection } from "./formatCollection";

export const URLs = Object.freeze({
  PADDLE_DETAILS_URL: "/paddle/",
  PADDLE_TRANSACTION_STATUS_URL: "/paddle/transaction-status/",
  CONFIG_URL: "/profile/config/",
  PLANS_URL: "/paddle/billing-subscription-plan/",
  REPORTS_PAGE_URL: "/reports/",
  REPORTS_URL: "/reports/data/?page=",
  REPORTS_PRODUCER_URL: "/reports/producer/data/?page=",
  USER_INFO_URL: "/users/user-info/",
  USER_INFO_USER_ADDRESS_HISTORY_URL: "/users/user-address-history/",
  USER_PROFILE_DELETE_ACCOUNT_URL: "/profile/delete-account/",
  PROFILE_API_KEYS_URL: "/profile/get-user-data/",
  USER_INFO_SAVE_PROFILE_URL: "/profile/update-user-profile-data/",
  PROFILE_STATISTICS_URL: "/profile/update-user-data/",
  PROFILE_PAYMENT_URL: "/profile/payment/",
  PROFILE_COLLECTIONS_URL: "/profile/collections/",
  ACCOUNTS_SIGNUP_URL: "/accounts/signup/",
  ACCOUNTS_LOGIN_URL: "/accounts/login/",
  VERIFY_URL: "/verify/",
  STAMP_URL: "/stamp/",
  PADDLE_CANCEL_URL: "/paddle/cancel/",
  CONTACTS_URL: "https://www.vbase.com/contact/",
  CONTACT_APP_URL: "/contact/",
  SUBSCRIPTION_SUCCESS_URL: "/paddle/subscription-success/",
  SUBSCRIPTION_FAILED_URL: "/paddle/subscription-failed/",
  USER_FOUND_DATA_URL: "/verify/find-user-objects/",
  USER_COLLECTIONS_URL: "/profile/#collections",
  USER_BILLING_URL: "/profile/#billing",
  VERUFY_API_URL: "/verify/cid/",
  VERIFY_USER_DATA_URL: "/verify/user-data/",
});

// Check user data type
function prepareRequestData(queryString) {
  const user = queryString.toLowerCase();
  if (user.startsWith("0x")) {
    return { user_address: user };
  } else if (user.startsWith("vbase")) {
    return { persistent_id: user };
  } else {
    return { user_name: user };
  }
}

export const userFoundData = async (
  setInputValues,
  user,
  setLoading,
  collectionName = "",
  collectionCid = "",
) => {
  const requestData = prepareRequestData(user);

  if (collectionName) {
    requestData.collection_name = collectionName;
  }
  if (collectionCid) {
    requestData.collection_cid = collectionCid;
  }

  try {
    setLoading(true);

    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(URLs.USER_FOUND_DATA_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(requestData),
    });
    if (!response.ok) {
      // Check for specific status 400
      if (response.status === 400) {
        setInputValues({
          user_logged_in: false,
        });
        setLoading(false); // Stop loading if response status is 400
        return; // Exit the function to prevent further execution
      } else {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`,
        );
      }
    }
    const data = await response.json();
    console.log(data);
    if (data) {
      const filteredReceipts = (data?.commitment_receipts_data || []).sort(
        (a, b) => {
          return new Date(b.timestamp) - new Date(a.timestamp);
        },
      );

      setInputValues({
        user_found: true,
        user_logged_in: true,
        collection_data: data?.collection_data || [],
        commitment_receipts_data: filteredReceipts,
        user_info_data: data?.user_info_data || {},
      });
    } else {
      setInputValues({
        user_found: false,
        user_logged_in: true,
      });
    }
    setLoading(false);
  } catch (error) {
    setInputValues({
      user_found: false,
      user_logged_in: true,
    });
    console.log(error);
  }
  setLoading(false);
};

export const paddleDataSuccess = async (setInputValues, setIsLoading) => {
  try {
    const response = await fetch(URLs.PADDLE_DETAILS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const dataList = await response.json();
    const data = dataList[0];
    setInputValues({
      subscription_info: {
        subscription_paddle_id:
          data.subscription_info?.subscription_paddle_id || false,
        last_transaction_paddle_id:
          data.subscription_info.last_transaction_paddle_id || false,
      },
      user_balance_info: {
        expirable_credits: data.user_balance_info.expirable_credits || 0,
        paid_credits: data.user_balance_info.paid_credits || 0,
        total_credits: data.user_balance_info.total_credits || 0,
      },
      subscription_plan: {
        title: data.subscription_plan?.title || false,
        total_quota_limit: data.subscription_plan?.total_quota_limit || 0,
      },
      billing_info: {
        email: data.billing_info?.email || "",
      },
    });
    setIsLoading(false); // Set loading to false when data is fetched
  } catch (error) {
    console.log(error);
    setIsLoading(false); // Handle errors by stopping the loading state
  }
};

export const fetchStatisticsData = async (setInputValues, setIsLoading) => {
  try {
    const response = await fetch(URLs.PROFILE_STATISTICS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    setInputValues({
      last_login: data.last_login || "",
      user_joined: data.user_joined || "",
    });
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const reportsData = async (page = 1, setInputValues) => {
  try {
    const response = await fetch(URLs.REPORTS_URL + page);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    setInputValues({
      allocations: data?.allocations || [],
      current_page: data?.current_page || 1,
      total_pages: data?.total_pages || 1,
      producer_identity: {
        name: data?.static_data?.producer_identity?.name || "",
        url: data?.static_data?.producer_identity?.url || "",
      },
      strategy_name: data?.static_data?.strategy_name || "",
      press_release_pdf: data?.static_data?.press_release_pdf || "",
      validation_information: {
        strategy_commits:
          data?.static_data?.validation_information?.strategy_commits || 0,
        other_strategies_commits:
          data?.static_data?.validation_information?.other_strategies_commits ||
          0,
        total_commits:
          data?.static_data?.validation_information?.total_commits || 0,
      },
      available_for_download:
        data?.static_data?.available_for_download || false,
      download_ZIP: data?.static_data?.download_ZIP || "",
      download_single_CSV: data?.static_data?.download_single_CSV || "",
    });
  } catch (error) {
    console.log(error);
  }
};
export const reportsProducerData = async (page = 1, setInputValues) => {
  try {
    const response = await fetch(URLs.REPORTS_PRODUCER_URL + page);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();

    setInputValues({
      strategies: data?.strategies || [],
      current_page: data?.current_page || 1,
      total_pages: data?.total_pages || 1,
      producer_identity: {
        name: data?.static_data?.producer_identity?.name || "",
        verified_by: data?.static_data?.producer_identity?.verified_by || "",
      },
      press_release: data?.static_data?.press_release || "",
      validation_information: {
        total_strategies:
          data?.static_data?.validation_information?.total_strategies || 0,
        total_commits:
          data?.static_data?.validation_information?.total_commits || 0,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

// This function fetches quota data from the server and checks if the quota banner should be shown
export const fetchQuotaData = async () => {
  try {
    const response = await fetch(URLs.PADDLE_DETAILS_URL);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    const quotaUsageInfo = data[0]?.subscription_plan?.total_quota_usage || 0;
    const userBalanceInfo = data[0]?.user_balance_info;
    const subscriptionInfo = data[0]?.subscription_info;
    const subscriptionPlan = data[0]?.subscription_plan;

    // Return the quota usage info and hide state
    return {
      quotaUsageInfo,
      userBalanceInfo,
      subscriptionPlan,
      subscriptionInfo,
    };
  } catch (error) {
    console.log(error);
    throw error; // In case the caller wants to handle the error
  }
};

export const fetchUserData = async (page = 1, setUserAddressHistoryData) => {
  try {
    const response = await fetch(
      URLs.USER_INFO_USER_ADDRESS_HISTORY_URL + "?page=" + page,
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    setUserAddressHistoryData(data);
  } catch (error) {
    console.log(error);
  }
};

//Create Name Collection
export const fetchCreateNameCollection = async (
  requestData,
  setNameCollectionError,
  setNameCollectionSuccess,
  setNameCollection,
  onShowConfirmationCollectionCreate,
  onUpdateStampQuota,
  handleLoading,
  close,
  form,
) => {
  try {
    handleLoading(true);
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(URLs.PROFILE_COLLECTIONS_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(requestData),
    });

    const result = await response.json();

    if (response.ok) {
      setNameCollectionError("");
      setNameCollection("");

      form.setFieldValue("collectionName", "");
      setNameCollectionSuccess(
        result.message +
          ": " +
          formatCollection(result.collection.name, result.collection.cid),
      );
      onUpdateStampQuota(Date.now());
      setTimeout(() => {
        setNameCollectionSuccess("");
      }, 5000);

      // Hide pop up
      close();
      // Hide Loading
      handleLoading(false);
    } else {
      setNameCollectionSuccess("");
      form.setFieldError("collectionName", result.error);
      console.log("Error:", result);

      // Hide pop up
      close();
      // Hide Loading
      handleLoading(false);
    }
  } catch (error) {
    setNameCollectionSuccess("");
    form.setFieldError("collectionName", error.message);
    console.log(error);

    // Hide pop up
    close();
    // Hide Loading
    handleLoading(false);
  }
};

//Set Name Collection
export const fetchSetNameCollection = async (
  requestData,
  setNameCollectionSuccess,
  onUpdateStampQuota,
  onOpenedModalSetName,
  setNameCollectionIsPinned,
  form,
  setLoading,
) => {
  try {
    setLoading(true);
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(URLs.PROFILE_COLLECTIONS_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(requestData),
    });

    const result = await response.json();

    if (response.ok) {
      form.setFieldValue("collectionName", "");
      onUpdateStampQuota(Date.now());
      setLoading(false);
      setNameCollectionIsPinned(response.url + requestData.cid);
      setNameCollectionSuccess("Collection Name added successfully");

      setTimeout(() => {
        onOpenedModalSetName(false);
      }, 4000);
      // Hide pop up
    } else {
      setNameCollectionSuccess("");
      form.setFieldError("collectionName", result.error);
      console.log("Error:", result);
      setLoading(false);
    }
  } catch (error) {
    setNameCollectionSuccess("");
    form.setFieldError("collectionName", error.message);
    console.log(error);
    setLoading(false);
  }
};

//All Collections is pinned
export const fetchAllCollectionsIsPinned = async (setInputValues) => {
  try {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(
      URLs.PROFILE_COLLECTIONS_URL + "?is_pinned=true&enable_pagination=false",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      },
    );

    const result = await response.json();

    if (response.ok) {
      setInputValues({
        usercollections: result?.usercollections || [],
        current_page: result?.current_page || 1,
        total_pages: result?.total_pages || 1,
      });
    } else {
      console.log("Error:", result);
    }
  } catch (error) {
    console.log(error);
  }
};

//All Collections is pinned without pagination
export const fetchAllCollectionsIsPinnedWithoutPagination = async (
  handleSelectCollections,
) => {
  try {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(
      URLs.PROFILE_COLLECTIONS_URL + "?enable_pagination=false&is_pinned=true",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      },
    );

    const result = await response.json();

    if (response.ok) {
      handleSelectCollections(result?.usercollections);
    } else {
      console.log("Error:", result);
    }
  } catch (error) {
    console.log(error);
  }
};

//All Collections is not pinned
export const fetchAllCollectionsIsNotPinned = async (
  setInputValuesIsNotPinned,
) => {
  try {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(
      URLs.PROFILE_COLLECTIONS_URL + "?is_pinned=false&enable_pagination=false",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
      },
    );

    const result = await response.json();

    if (response.ok) {
      setInputValuesIsNotPinned({
        usercollections: result?.usercollections || [],
        current_page: result?.current_page || 1,
        total_pages: result?.total_pages || 1,
      });
    } else {
      console.log("Error:", result);
    }
  } catch (error) {
    console.log(error);
  }
};

// Pin/Unpin Collection
export const fetchIsPinnedCollection = async (
  collection_id,
  isPinned,
  onOpenedModalPinned,
  setNameCollectionIsPinned,
) => {
  try {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(
      URLs.PROFILE_COLLECTIONS_URL + collection_id + "/",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ is_pinned: isPinned }),
      },
    );
    if (response.ok) {
      console.log(response);
      setNameCollectionIsPinned(response.url + isPinned);
      onOpenedModalPinned(false);
    }
  } catch (error) {
    console.log(error);
    onOpenedModalPinned(false);
  }
};

// Add/Edit Description Collection
export const fetchPutDescriptionCollection = async (
  collection_id,
  description,
  onOpenedModalDescription,
  onDescription,
  form,
  setDescriptionCreateSuccess,
) => {
  try {
    const csrfToken = document.querySelector(
      "[name=csrfmiddlewaretoken]",
    ).value;

    const response = await fetch(
      URLs.PROFILE_COLLECTIONS_URL + collection_id + "/",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify({ description: description }),
      },
    );
    if (response.ok) {
      console.log(response.url + description);
      onDescription(response.url + description);

      setDescriptionCreateSuccess(true);
      setTimeout(() => {
        setDescriptionCreateSuccess(false);
        onOpenedModalDescription(false);
      }, 2000);
    } else {
      console.log(response);
      form.setFieldError("collectionDescription", response.error);
    }
  } catch (error) {
    console.log(error);
    form.setFieldError("collectionDescription", error);
  }
};

export const footerForm = async (values, setSuccess, form) => {
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  const email = values.email;
  const formData = { email };

  try {
    const response = await fetch(URLs.CONTACT_APP_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (!response.ok) {
      form.setFieldError("email", data.error);
      throw new Error(data.error || "Something went wrong");
    }
    form.setFieldValue("email", "");
    setSuccess("Thank You!");
  } catch (error) {
    form.setFieldError("email", "Error");

    console.log(error);
  }
};

import React from "react";
import {
  ActionIcon,
  CopyButton,
  Flex,
  rem,
  Tooltip,
  Text,
  Box,
} from "@mantine/core";

import { IconCheck, IconCopy } from "@tabler/icons-react";
import CustomButton from "../../components/mantineCustomComponets/CustomButton";
import { formatHexStr } from "../formatHexStr";
import CustomAnchor from "../../components/mantineCustomComponets/CustomAnchor";

// Function for creating data
export const collectionPinnedTableData = (
  contentCollectionsPinned,
  setCollectionID,
  setIsPinned,
  setOpenedModalPinned,
  setOpenedModalDescription,
  form,
  userShortName,
) => {
  if (!Array.isArray(contentCollectionsPinned)) {
    return [];
  }

  return contentCollectionsPinned.map((item) => ({
    name: (
      <CustomAnchor
        componentClasses="link link_black nav-link"
        text={item.name}
        href={`/verify/user-data/?user=${userShortName}&collection_name=${item.name}`}
      />
    ),
    description: (
      <Box>
        <Text lineClamp={4}>{item.description}</Text>

        <CustomButton
          text={item.description ? "Edit Description" : "Add Description"}
          componentClasses="link"
          variant="transparent"
          type="button"
          onClick={() => {
            form.setValues({ collectionDescription: item.description });
            form.setValues({ collectionName: item.name });
            setCollectionID(item.id);
            setOpenedModalDescription(true);
          }}
        ></CustomButton>
      </Box>
    ),
    cid: (
      <Flex align="center" gap={2}>
        <CustomAnchor
          componentClasses="link link_black nav-link"
          text={formatHexStr(item.cid)}
          href={`/verify/user-data/?user=${userShortName}&collection_cid=${item.cid}`}
        />

        <CopyButton value={item.cid} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? "Copied" : "Copy"}
              withArrow
              position="right"
            >
              <ActionIcon
                color={copied ? "teal" : "gray"}
                variant="subtle"
                onClick={copy}
              >
                {copied ? (
                  <IconCheck style={{ width: rem(16) }} />
                ) : (
                  <IconCopy style={{ width: rem(16) }} />
                )}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Flex>
    ),
    action: (
      <CustomButton
        text="Unpin"
        componentClasses="link linkWidth90"
        variant="transparent"
        type="button"
        onClick={() => {
          setIsPinned(false);
          setCollectionID(item.id);
          setOpenedModalPinned(true);
        }}
      ></CustomButton>
    ),
  }));
};

// Function for creating columns
export const collectionPinnedTableColumns = () => [
  {
    accessorKey: "name",
    header: "Collection Name",
    enableSorting: true,
    size: 250,
    sortingFn: (rowA, rowB) => {
      const nameA = rowA.original.name.toLowerCase();
      const nameB = rowB.original.name.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "description",
    header: "Collection Description",
    enableSorting: true,
    size: 250,
    sortingFn: (rowA, rowB) => {
      const descriptionA = rowA.original.description.toLowerCase();
      const descriptionB = rowB.original.description.toLowerCase();

      if (descriptionA < descriptionB) return -1;
      if (descriptionA > descriptionB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "cid",
    header: "Collection ID",
    size: 100,
    sortingFn: (rowA, rowB) => {
      const cidA = rowA.original.cid.props?.children?.[0] || "";
      const cidB = rowB.original.cid.props?.children?.[0] || "";
      if (cidA < cidB) return -1;
      if (cidA > cidB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "action",
    header: "Action",
    enableSorting: false,
    size: 40,
    mantineTableBodyCellProps: {
      align: "right",
    },
  },
];

// Function for configuring the table
export const collectionPinnedTableOptions = (
  columns,
  data,
  faIcons,
  theme,
) => ({
  columns,
  data,
  icons: faIcons,
  paginationDisplayMode: "pages",
  enableFullScreenToggle: false,
  enableTopToolbar: false,
  enableBottomToolbar: true,
  enableColumnActions: false,
  initialState: { pagination: { pageIndex: 0, pageSize: 12 } },
  withColumnBorders: true,
  mantinePaginationProps: () => ({
    showRowsPerPage: false,
    color: theme.customBackgrounds.table,
    autoContrast: true,
    style: {
      paddingBottom: "1.75em",
    },
  }),
  mantineTableProps: {
    style: {
      border: "none",
      borderCollapse: "collapse",
    },
    className: "html-table html-table-custom html-table-pinned",
  },
  mantinePaperProps: {
    withBorder: false,
    shadow: "none",
    className: "html-table-custom-wrap",
  },
  mantineTableBodyCellProps: {
    style: {
      padding: "0.6875em 0.9375em 0.5625em 0.9375em",
      fontSize: "1.142em",
    },
  },
  mantineTableHeadCellProps: {
    style: {
      padding: "0.55em 0.9375em 0.4375em 0.9375em",
      fontSize: "1.142em",
      fontFamily: theme.headings.fontFamily.bold,
    },
  },
  mantineTableBodyRowProps: () => ({
    style: {
      borderBottom: theme.customColors.tableBorder,
    },
  }),
  mantineTableHeadRowProps: () => ({
    style: {
      backgroundColor: theme.customBackgrounds.table,
    },
  }),
});

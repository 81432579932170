import { createStyles } from "@mantine/emotion";

export const useStylesIcons = createStyles(() => ({
  title: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "1.8em",
  },
  userVerified: {
    width: "1.5rem",
    height: "1.5rem",
  },
  copy: {
    marginLeft: "0.2em",
    marginRight: "0.2em",
  },
}));

import PropTypes from "prop-types";
import React from "react";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Placeholder from "@tiptap/extension-placeholder";
import "@mantine/tiptap/styles.css";
import { Box } from "@mantine/core";
import { useStylesTipTapEditor } from "../../utils/mantine/useStylesTipTapEditor";

// Function to decode HTML entities to their actual content
const decodeHTML = (html) => {
  const textArea = document.createElement("textarea"); // Create a temporary textarea element
  textArea.innerHTML = html; // Set the HTML content as the innerHTML
  return textArea.value; // Return the decoded value
};

const TipTapEditor = ({ value, onChange, error }) => {
  // Decode the value for displaying in the editor
  const decodedValue = decodeHTML(value);

  // Use custom styles for the TipTap editor
  const { classes } = useStylesTipTapEditor();

  // Initialize the editor with various extensions
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Placeholder.configure({ placeholder: "About" }),
    ],
    content: decodedValue, // Set the decoded content as the editor's initial value
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML()); // Update the parent component with the new HTML content on change
    },
  });

  return (
    <Box className={classes.editor}>
      {/* Render the RichTextEditor component with the initialized editor */}
      <RichTextEditor editor={editor}>
        {/* Toolbar with various controls for formatting */}
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          {/* Group of text style controls */}
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          {/* Group of heading controls */}
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          {/* Group of block formatting controls */}
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
          </RichTextEditor.ControlsGroup>

          {/* Group of link controls */}
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>

          {/* Group of alignment controls */}
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignJustify />
            <RichTextEditor.AlignRight />
          </RichTextEditor.ControlsGroup>

          {/* Group of undo/redo controls */}
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        {/* Editor content */}
        <RichTextEditor.Content />

        {/* Display error message if there's an error */}
        {error && (
          <Box
            style={(theme) => ({
              fontSize: "0.93em",
              lineHeight: "0.93em",
              position: "absolute",
              color: theme.customColors.red,
              fontFamily: theme.headings.fontFamily.light,
              width: "23rem",
            })}
          >
            {error}
          </Box>
        )}
      </RichTextEditor>
    </Box>
  );
};

export default TipTapEditor;

TipTapEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

import PropTypes from "prop-types";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import StampFound from "../components/StampFound";
import StampNotFound from "../components/StampNotFound";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { processFile } from "../utils/processFile";
import { verifyHashWorkerVerify } from "../utils/verifyHashWorkerVerify";
import VerifyContentId from "../components/VerifyContentId";
import VerifyFile from "../components/VerifyFile";
import VerifyUserFound from "../components/VerifyUserFound";
import VerifyCollection from "../components/VerifyCollection";
import Cookies from "js-cookie";
import TriggerHelp from "../components/TriggerHelp";
import Help from "../components/Help";
import NeedHelp from "../components/NeedHelp";
import LearnMore from "../components/landing-components/LearnMore";
import Landing from "../components/landing-components/Index";
import Loading from "../components/Loading";
import { URLs } from "../utils/apis";
import CollectionFound from "../components/CollectionFound";
import CollectionNotFound from "../components/CollectionNotFound";

const VerifyForm = ({ staticBasePath, djangoContext }) => {
  const [helpIsActive, setHelpIsActive] = useState(() => {
    const cookieValue = Cookies.get("helpIsActive");
    return cookieValue !== undefined ? JSON.parse(cookieValue) : true;
  });

  const handleHelpTriggerClick = () => {
    const newHelpIsActive = !helpIsActive;
    setHelpIsActive(newHelpIsActive);
    Cookies.set("helpIsActive", JSON.stringify(newHelpIsActive));
  };

  const [verificationMethod, setVerificationMethod] = useState(() => {
    const cookieVerificationMethod = Cookies.get("verificationMethod");
    return cookieVerificationMethod !== undefined
      ? JSON.parse(cookieVerificationMethod)
      : "file";
  });
  const [objectHash, setObjectHash] = useState(null);
  const [userData, setUserData] = useState("");
  const [stampData, setStampData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentScreen, setCurrentScreen] = useState("verify"); // new state to manage screen
  const [isValid, setIsValid] = useState(true);
  const regex = /^(0x)?[0-9a-fA-F]{64}$/;
  const isAuthenticated = useMemo(() => djangoContext.userIsAuthenticated, []);
  useEffect(() => {
    const handleLocationChange = () => {
      const params = new URLSearchParams(window.location.search);
      const cidParam = params.get("cid");
      const checkValid = regex.test(cidParam) || cidParam === "";
      setIsValid(checkValid);
      if (checkValid) {
        setObjectHash(cidParam);
        handleVerifyHashWorkerVerify(cidParam, undefined);
      }
    };

    window.addEventListener("popstate", handleLocationChange);
    handleLocationChange();
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const handleDefaultScreen = () => {
    setError(false);
    setCurrentScreen("");
    setObjectHash("");

    const url = new URL(window.location);
    if (url.searchParams.has("cid")) {
      url.searchParams.delete("cid");
      window.history.pushState({}, "", url);
    }
  };

  const handleVerificationMethodChange = (method) => {
    setVerificationMethod(method);
    Cookies.set("verificationMethod", JSON.stringify(method));
  };

  const handleFileInput = (event) => {
    event.preventDefault();
    const file = event.target.files?.[0] || event.dataTransfer?.files?.[0];
    if (file) {
      handleProcessFile(file);
    }
  };

  const handleHashChange = (event) => {
    const { value } = event.target;
    setIsValid(regex.test(value) || value === "");
    setObjectHash(value);
  };

  const handleUserDataChange = (event) => {
    const { value } = event.target;
    setUserData(value);
  };

  const handleSubmit = () => {
    if (objectHash !== "") {
      handleVerifyHashWorkerVerify(objectHash, undefined);
    }
  };

  const handleUserDataSubmit = () => {
    if (userData !== "") {
      const urlUserFound = new URL(
        URLs.VERIFY_USER_DATA_URL,
        window.location.origin,
      );
      urlUserFound.searchParams.set("user", userData);
      window.location.href = urlUserFound;
    }
  };

  const [allHashes, setAllHashes] = useState({});
  const handleProcessFile = (file) => {
    // get hash with web3
    processFile(
      file,
      setObjectHash,
      handleVerifyHashWorkerVerify,
      setAllHashes,
    );
  };

  const handleVerifyHashWorkerVerify = (hash, supportedHashes) => {
    // fetch /verify/hash/
    verifyHashWorkerVerify(
      hash,
      supportedHashes,
      setError,
      setStampData,
      setCurrentScreen,
      setObjectHash,
      setLoading,
    );
  };
  const [isNearBottom, setIsNearBottom] = useState(false);
  const handleScroll = () => {
    const rbMainContent = document.querySelector(".rb-main-content");

    if (!rbMainContent) return;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const contentOffsetTop = rbMainContent.offsetTop;
    const contentHeight = rbMainContent.offsetHeight;
    const windowHeight = window.innerHeight;
    const remainingScroll =
      contentOffsetTop + contentHeight - (scrollTop + windowHeight);

    let heightBottom;

    heightBottom = -28;

    if (
      (remainingScroll <= 80 && window.innerWidth <= 639) ||
      (remainingScroll <= heightBottom && window.innerWidth >= 640)
    ) {
      setIsNearBottom(true);
    } else {
      setIsNearBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const rbRoot = document.querySelector(".rb-root");
    if (currentScreen === "found" || error) {
      rbRoot.classList.remove("full-screen-min-height");
    }

    return () => {
      rbRoot.classList.remove("full-screen-min-height");
    };
  }, [currentScreen, error]);

  const landingSectionRef = useRef(null);

  // Collection Found
  const [showCollectionFound, setShowCollectionFound] = useState(false);

  const handleShowCollectionFound = useCallback((bool) => {
    setShowCollectionFound(bool);
  }, []);

  const [contentCollectionFound, setContentCollectionFound] = useState([]);

  const handleContentCollectionFound = useCallback((array) => {
    setContentCollectionFound(array);
  }, []);

  const [fileName, setFileName] = useState("");

  const handleFileName = useCallback((name) => {
    setFileName(name);
  }, []);

  const [fileType, setFileType] = useState("");

  const handleFileType = useCallback((type) => {
    setFileType(type);
  }, []);

  const [errorMessage, setErrorMessage] = useState("");

  const handleErrorMessage = useCallback((message) => {
    setErrorMessage(message);
  }, []);

  //Link anchors verify tabs
  useEffect(() => {
    const hash = window.location.hash.substring(1); // Get the part after #
    const urlParams = new URLSearchParams(window.location.search);
    const verifyParam = urlParams.get("method");

    // Define valid verification methods
    const validMethods = ["file", "hash", "user", "csv"];

    // Check if the hash or query param contains a valid method
    const method = validMethods.find(
      (m) => hash.includes(m) || verifyParam === m,
    );
    if (method) {
      handleVerificationMethodChange(method);
    }
  }, []);

  return (
    <>
      <Header staticBasePath={staticBasePath} djangoContext={djangoContext} />
      <div className="rb-main-content rb-main-content-verify">
        {error ? (
          <div className="error-message">{error}</div>
        ) : currentScreen === "found" ? (
          <>
            <StampFound
              key={stampData.id}
              data={stampData}
              staticBasePath={staticBasePath}
              handleDefaultScreen={handleDefaultScreen}
              allHashes={allHashes}
            />
          </>
        ) : currentScreen === "notfound" ? (
          <StampNotFound
            hash={objectHash}
            staticBasePath={staticBasePath}
            handleDefaultScreen={handleDefaultScreen}
            verificationMethod={verificationMethod}
          />
        ) : (
          <>
            {showCollectionFound || errorMessage ? (
              <>
                {showCollectionFound && (
                  <CollectionFound
                    contentCollectionFound={contentCollectionFound}
                    staticBasePath={staticBasePath}
                    fileName={fileName}
                    fileType={fileType}
                  />
                )}

                {errorMessage && (
                  <CollectionNotFound
                    staticBasePath={staticBasePath}
                    errorMessage={errorMessage}
                  />
                )}
              </>
            ) : (
              <>
                <div className="rb-screen">
                  {loading && (
                    <Loading text="One moment! Searching for the stamp..." />
                  )}

                  <div className="rb-header-wrap">
                    <p className="rb-header rb-text-center">
                      Verify{" "}
                      {(verificationMethod === "user" ||
                        verificationMethod === "csv") &&
                        "a"}{" "}
                      <b>vBase</b>
                      {verificationMethod === "user"
                        ? " User"
                        : verificationMethod === "csv"
                        ? " Collection"
                        : " Stamps"}
                    </p>
                    <p className="rb-subheader rb-text-center">
                      {verificationMethod === "user"
                        ? "View stamping activity of a vBase user"
                        : verificationMethod === "csv"
                        ? "Verify the authenticity and integrity of a Collection"
                        : "Validate the author, content and timestamp for any vBase Stamp"}
                    </p>
                    <NeedHelp
                      staticBasePath={staticBasePath}
                      linkDocs="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-verify"
                    />
                  </div>
                  <div className="rb-method-wrapper">
                    <div
                      id="verify-method-chooser"
                      className="rb-btns-select rb-mt-normal"
                      data-method={verificationMethod}
                    >
                      <button
                        id="verify-method-file"
                        className={`rb-btn rb-btn-file ${
                          verificationMethod === "file" ? "rb-btn-selected" : ""
                        }`}
                        onClick={() => handleVerificationMethodChange("file")}
                      >
                        File
                      </button>
                      <button
                        id="verify-method-hash"
                        className={`rb-btn rb-btn-hash ${
                          verificationMethod === "hash" ? "rb-btn-selected" : ""
                        }`}
                        onClick={() => handleVerificationMethodChange("hash")}
                      >
                        Content ID
                      </button>
                      <button
                        id="user-found"
                        className={`rb-btn rb-btn-user-found ${
                          verificationMethod === "user" ? "rb-btn-selected" : ""
                        }`}
                        onClick={() => handleVerificationMethodChange("user")}
                      >
                        User
                      </button>
                      <button
                        id="long-csv"
                        className={`rb-btn rb-btn-long-csv ${
                          verificationMethod === "csv" ? "rb-btn-selected" : ""
                        }`}
                        onClick={() => handleVerificationMethodChange("csv")}
                      >
                        Collection
                      </button>
                      {helpIsActive && (
                        <Help
                          position="right"
                          text="Select whether to validate the stamps associated with a given File or Content ID. Or, validate the Stamp activity of a user."
                          link="#"
                        />
                      )}
                    </div>
                  </div>

                  {verificationMethod === "file" && (
                    <VerifyFile
                      handleFileInput={handleFileInput}
                      staticBasePath={staticBasePath}
                      helpIsActive={helpIsActive}
                    />
                  )}

                  {verificationMethod === "hash" && (
                    <VerifyContentId
                      isValid={isValid}
                      objectHash={objectHash}
                      handleHashChange={handleHashChange}
                      handleSubmit={handleSubmit}
                    />
                  )}

                  {verificationMethod === "user" && (
                    <VerifyUserFound
                      userData={userData}
                      onUserDataChange={handleUserDataChange}
                      onUserDataSubmit={handleUserDataSubmit}
                      isAuthenticated={isAuthenticated}
                    />
                  )}
                  {verificationMethod === "csv" && (
                    <VerifyCollection
                      onShowCollectionFound={handleShowCollectionFound}
                      onContentCollectionFound={handleContentCollectionFound}
                      onFileName={handleFileName}
                      onErrorMessage={handleErrorMessage}
                      onFileType={handleFileType}
                      isAuthenticated={isAuthenticated}
                    />
                  )}
                  <div className="stamp-bulk__text">
                    Need to verify many stamps?{" "}
                    <a
                      href="https://docs.vbase.com/getting-started/python-quickstart"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Check out our handy API
                    </a>
                  </div>
                </div>
                <TriggerHelp
                  helpIsActive={helpIsActive}
                  isNearBottom={isNearBottom}
                  handleHelpTriggerClick={handleHelpTriggerClick}
                />
              </>
            )}
          </>
        )}
        {!showCollectionFound && !errorMessage && (
          <LearnMore
            staticBasePath={staticBasePath}
            landingSectionRef={landingSectionRef}
          />
        )}
      </div>
      {!showCollectionFound && !errorMessage && (
        <Landing
          staticBasePath={staticBasePath}
          isAuthenticated={isAuthenticated}
          landingSectionRef={landingSectionRef}
        />
      )}
      <Footer staticBasePath={staticBasePath} />
      {loading && <div className="rb-loading-bg"></div>}
    </>
  );
};
VerifyForm.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  djangoContext: PropTypes.object.isRequired,
};
export default VerifyForm;

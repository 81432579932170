import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import BillingHistory from "./BillingHistory";
import { URLs } from "../../utils/apis";
import {
  Box,
  Button,
  Flex,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { formatTimeStamp } from "../../utils/collectionFound/dateTimeUtils";

const Billing = ({ timeZone }) => {
  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const [canclelShow, setCanclelShow] = useState(false);
  const [canclelMessage, setCanclelMessage] = useState(false);

  const [billingHistoryShow, setBillingHistoryShow] = useState(false);
  const handleClickBillingHistory = () => {
    setBillingHistoryShow(true);
  };

  const handleClickShowCancel = () => {
    setCanclelShow(!canclelShow);
  };

  const handleClickHideCancel = () => {
    setCanclelShow(false);
  };

  const handleClickCancel = async () => {
    setCanclelShow(false);
    try {
      const response = await fetch(URLs.PADDLE_CANCEL_URL);

      const data = await response.json();
      // Check if the status is 400
      if (response.status === 400) {
        setCanclelMessage(data.message);
        return; // Stop further execution if status is 400
      }

      if (!response.ok) {
        setCanclelMessage("Error");
      }

      setCanclelMessage(data.message);

      // Delay the page reload by 3 seconds
      setTimeout(() => {
        window.location.reload(); // This will refresh the page after 3 seconds
      }, 3000);
    } catch (error) {
      setCanclelMessage(error);
    }
  };

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    subscription_info: {
      is_subscription_active: false,
      is_subscription_paused: false,
      is_subscription_cancelled: false,
      is_subscription_to_be_cancelled: false,
      is_subscription_to_be_paused: false,
      subscription_started_at: false,
      next_billed_at: false,
      paused_at: false,
      canceled_at: false,
      subscription_type: false,
    },
    payment_info: {
      payment_type: false,
      card_type: false,
      last4: false,
      verbose_type: false,
    },
    billing_info: {
      billed_to: false,
      email: false,
      vat_number: false,
    },
    user_balance_info: {
      expirable_credits: "",
      paid_credits: "",
      total_credits: "",
    },
    subscription_actions: {
      cancel: false,
    },
    subscription_plan: {
      title: false,
      description: false,
    },
  });

  // Fetch user data from API
  useEffect(() => {
    const paddleData = async () => {
      try {
        const response = await fetch(URLs.PADDLE_DETAILS_URL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setInputValues({
          subscription_info: {
            is_subscription_active:
              data[0]?.subscription_info?.is_subscription_active || false,
            is_subscription_paused:
              data[0]?.subscription_info?.is_subscription_paused || false,
            is_subscription_cancelled:
              data[0]?.subscription_info?.is_subscription_cancelled || false,
            is_subscription_to_be_cancelled:
              data[0]?.subscription_info?.is_subscription_to_be_cancelled ||
              false,
            is_subscription_to_be_paused:
              data[0]?.subscription_info?.is_subscription_to_be_paused || false,
            subscription_started_at:
              data[0]?.subscription_info?.subscription_started_at || false,
            next_billed_at: data[0]?.subscription_info?.next_billed_at || false,
            paused_at: data[0]?.subscription_info?.paused_at || false,
            canceled_at: data[0]?.subscription_info?.canceled_at || false,
            subscription_type:
              data[0]?.subscription_info?.subscription_type || false,
          },
          payment_info: {
            payment_type: data[0]?.payment_info?.payment_type || false,
            card_type: data[0]?.payment_info?.card_type || false,
            last4: data[0]?.payment_info?.last4 || false,
            verbose_type: data[0]?.payment_info?.verbose_type || false,
          },
          billing_info: {
            billed_to: data[0]?.billing_info?.billed_to || false,
            email: data[0]?.billing_info?.email || false,
            vat_number: data[0]?.billing_info?.vat_number || false,
          },
          user_balance_info: {
            expirable_credits:
              data[0]?.user_balance_info?.expirable_credits || 0,
            paid_credits: data[0]?.user_balance_info?.paid_credits || 0,
            total_credits: data[0]?.user_balance_info?.total_credits || 0,
          },
          subscription_actions: {
            cancel: data[0]?.subscription_actions?.cancel || false,
          },
          subscription_plan: {
            title: data[0]?.subscription_plan?.title || false,
            description: data[0]?.subscription_plan?.description || false,
          },
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    paddleData();
  }, []); // Empty dependency array means this effect runs once on mount
  if (isLoading) {
    return (
      <Box>
        <Flex
          justify="center"
          align="center"
          styles={() => ({
            root: {
              minHeight: 400,
            },
          })}
        >
          Loading...
        </Flex>
      </Box>
    ); // Show a loading message while data is being fetched
  }
  return (
    <>
      {billingHistoryShow ? (
        <>
          <BillingHistory
            setBillingHistoryShow={setBillingHistoryShow}
            timeZone={timeZone}
          />
        </>
      ) : (
        <Box
          className="billing"
          style={() => ({
            paddingTop: 83,
            paddingBottom: 100,
          })}
        >
          <Flex
            gap={20}
            mb={32}
            align="center"
            wrap={tabletMediaQuery ? "wrap" : "nowrap"}
            justify={tabletMediaQuery ? "flex-start" : "center"}
          >
            <Title
              className="billing-tab-title"
              order={2}
              styles={(theme) => ({
                root: {
                  fontSize: "1.5em",
                  fontFamily: theme.headings.fontFamily.secondary,
                  minWidth: 185,
                },
              })}
            >
              Subscription Info
            </Title>
          </Flex>
          {inputValues["subscription_plan"]["title"] && (
            <Flex
              gap={20}
              mb={32}
              align="center"
              wrap={tabletMediaQuery ? "wrap" : "nowrap"}
              justify={tabletMediaQuery ? "flex-start" : "center"}
            >
              <TextInput
                disabled
                className="active-billing-plan"
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  },
                  label: {
                    paddingRight: 20,
                    fontFamily: theme.headings.fontFamily.bold,
                    fontSize: "1.2em",
                    width: 236,
                    textAlign: tabletMediaQuery ? "left" : "right",
                  },
                  input: {
                    paddingRight: 20,
                    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                    maxWidth: "100%",
                    width: tabletMediaQuery ? "100%" : "22rem",
                  },
                })}
                label="Current Subscription Plan:"
                value={inputValues["subscription_plan"]["title"]}
              />
            </Flex>
          )}
          {inputValues["subscription_info"]["is_subscription_active"] && (
            <Flex
              gap={20}
              mb={32}
              align="center"
              wrap={tabletMediaQuery ? "wrap" : "nowrap"}
              justify={tabletMediaQuery ? "flex-start" : "center"}
            >
              <TextInput
                disabled
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  },
                  label: {
                    paddingRight: 20,
                    fontFamily: theme.headings.fontFamily.bold,
                    fontSize: "1.2em",
                    width: 236,
                    textAlign: tabletMediaQuery ? "left" : "right",
                  },
                  input: {
                    paddingRight: 20,
                    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                    maxWidth: "100%",
                    width: tabletMediaQuery ? "100%" : "22rem",
                  },
                })}
                label="Subscription Active:"
                value={
                  inputValues["subscription_info"]["is_subscription_active"]
                    ? "Yes"
                    : "No"
                }
              />
            </Flex>
          )}

          {inputValues["subscription_info"]["subscription_type"] && (
            <Flex
              gap={20}
              mb={32}
              align="center"
              wrap={tabletMediaQuery ? "wrap" : "nowrap"}
              justify={tabletMediaQuery ? "flex-start" : "center"}
            >
              <TextInput
                disabled
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  },
                  label: {
                    paddingRight: 20,
                    fontFamily: theme.headings.fontFamily.bold,
                    fontSize: "1.2em",
                    width: 236,
                    textAlign: tabletMediaQuery ? "left" : "right",
                  },
                  input: {
                    paddingRight: 20,
                    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                    maxWidth: "100%",
                    width: tabletMediaQuery ? "100%" : "22rem",
                  },
                })}
                label="Subscription Type:"
                value={inputValues["subscription_info"]["subscription_type"]}
              />
            </Flex>
          )}
          {inputValues["subscription_info"]["is_subscription_paused"] ? (
            <Flex
              gap={20}
              mb={32}
              align="center"
              wrap={tabletMediaQuery ? "wrap" : "nowrap"}
              justify={tabletMediaQuery ? "flex-start" : "center"}
            >
              <TextInput
                disabled
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  },
                  label: {
                    paddingRight: 20,
                    fontFamily: theme.headings.fontFamily.bold,
                    fontSize: "1.2em",
                    width: 236,
                    textAlign: tabletMediaQuery ? "left" : "right",
                  },
                  input: {
                    paddingRight: 20,
                    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                    maxWidth: "100%",
                    width: tabletMediaQuery ? "100%" : "22rem",
                  },
                })}
                label="Subscription Paused:"
                value={
                  inputValues["subscription_info"]["is_subscription_paused"]
                    ? "Yes"
                    : "No"
                }
              />
            </Flex>
          ) : inputValues["subscription_info"]["is_subscription_cancelled"] ? (
            <Flex
              gap={20}
              mb={32}
              align="center"
              wrap={tabletMediaQuery ? "wrap" : "nowrap"}
              justify={tabletMediaQuery ? "flex-start" : "center"}
            >
              <TextInput
                disabled
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  },
                  label: {
                    paddingRight: 20,
                    fontFamily: theme.headings.fontFamily.bold,
                    fontSize: "1.2em",
                    width: 236,
                    textAlign: tabletMediaQuery ? "left" : "right",
                  },
                  input: {
                    paddingRight: 20,
                    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                    maxWidth: "100%",
                    width: tabletMediaQuery ? "100%" : "22rem",
                  },
                })}
                label="Subscription Cancelled:"
                value={
                  inputValues["subscription_info"]["is_subscription_cancelled"]
                    ? "Yes"
                    : "No"
                }
              />
            </Flex>
          ) : (
            <></>
          )}
          {inputValues["subscription_info"]["subscription_started_at"] && (
            <Flex
              gap={20}
              mb={32}
              align="center"
              wrap={tabletMediaQuery ? "wrap" : "nowrap"}
              justify={tabletMediaQuery ? "flex-start" : "center"}
            >
              <TextInput
                disabled
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  },
                  label: {
                    paddingRight: 20,
                    fontFamily: theme.headings.fontFamily.bold,
                    fontSize: "1.2em",
                    width: 236,
                    textAlign: tabletMediaQuery ? "left" : "right",
                  },
                  input: {
                    paddingRight: 20,
                    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                    maxWidth: "100%",
                    width: tabletMediaQuery ? "100%" : "22rem",
                  },
                })}
                label="Subscription Start Date:"
                value={formatTimeStamp(
                  inputValues["subscription_info"]["subscription_started_at"],
                  timeZone,
                )}
              />
            </Flex>
          )}

          {inputValues["subscription_info"]["next_billed_at"] && (
            <Flex
              gap={20}
              mb={32}
              align="center"
              wrap={tabletMediaQuery ? "wrap" : "nowrap"}
              justify={tabletMediaQuery ? "flex-start" : "center"}
            >
              <TextInput
                disabled
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  },
                  label: {
                    paddingRight: 20,
                    fontFamily: theme.headings.fontFamily.bold,
                    fontSize: "1.2em",
                    width: 236,
                    textAlign: tabletMediaQuery ? "left" : "right",
                  },
                  input: {
                    paddingRight: 20,
                    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                    maxWidth: "100%",
                    width: tabletMediaQuery ? "100%" : "22rem",
                  },
                })}
                label="Next Billing Date:"
                value={formatTimeStamp(
                  inputValues["subscription_info"]["next_billed_at"],
                  timeZone,
                )}
              />
            </Flex>
          )}

          {inputValues["subscription_info"]["paused_at"] && (
            <Flex
              gap={20}
              mb={32}
              align="center"
              wrap={tabletMediaQuery ? "wrap" : "nowrap"}
              justify={tabletMediaQuery ? "flex-start" : "center"}
            >
              <TextInput
                disabled
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  },
                  label: {
                    paddingRight: 20,
                    fontFamily: theme.headings.fontFamily.bold,
                    fontSize: "1.2em",
                    width: 236,
                    textAlign: tabletMediaQuery ? "left" : "right",
                  },
                  input: {
                    paddingRight: 20,
                    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                    maxWidth: "100%",
                    width: tabletMediaQuery ? "100%" : "22rem",
                  },
                })}
                label="Paused At:"
                value={formatTimeStamp(
                  inputValues["subscription_info"]["paused_at"],
                  timeZone,
                )}
              />
            </Flex>
          )}

          {inputValues["subscription_info"]["canceled_at"] && (
            <Flex
              gap={20}
              mb={32}
              align="center"
              wrap={tabletMediaQuery ? "wrap" : "nowrap"}
              justify={tabletMediaQuery ? "flex-start" : "center"}
            >
              <TextInput
                disabled
                styles={(theme) => ({
                  root: {
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: tabletMediaQuery ? "flex-start" : "center",
                  },
                  label: {
                    paddingRight: 20,
                    fontFamily: theme.headings.fontFamily.bold,
                    fontSize: "1.2em",
                    width: 236,
                    textAlign: tabletMediaQuery ? "left" : "right",
                  },
                  input: {
                    paddingRight: 20,
                    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                    fontSize: "1rem",
                    lineHeight: "1.25rem",
                    maxWidth: "100%",
                    width: tabletMediaQuery ? "100%" : "22rem",
                  },
                })}
                label="Canceled At:"
                value={formatTimeStamp(
                  inputValues["subscription_info"]["canceled_at"],
                  timeZone,
                )}
              />
            </Flex>
          )}

          {inputValues["payment_info"]["payment_type"] === "card" ? (
            <>
              <Flex
                gap={20}
                mb={32}
                align="center"
                wrap={tabletMediaQuery ? "wrap" : "nowrap"}
                justify={tabletMediaQuery ? "flex-start" : "center"}
              >
                <Title
                  order={2}
                  styles={(theme) => ({
                    root: {
                      fontSize: "1.5em",
                      fontFamily: theme.headings.fontFamily.secondary,
                      minWidth: 185,
                    },
                  })}
                >
                  Payment Info
                </Title>
              </Flex>
              {inputValues["payment_info"]["card_type"] && (
                <Flex
                  gap={20}
                  mb={32}
                  align="center"
                  wrap={tabletMediaQuery ? "wrap" : "nowrap"}
                  justify={tabletMediaQuery ? "flex-start" : "center"}
                >
                  <TextInput
                    disabled
                    styles={(theme) => ({
                      root: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: tabletMediaQuery
                          ? "flex-start"
                          : "center",
                      },
                      label: {
                        paddingRight: 20,
                        fontFamily: theme.headings.fontFamily.bold,
                        fontSize: "1.2em",
                        width: 236,
                        textAlign: tabletMediaQuery ? "left" : "right",
                      },
                      input: {
                        paddingRight: 20,
                        border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                        fontSize: "1rem",
                        lineHeight: "1.25rem",
                        maxWidth: "100%",
                        width: tabletMediaQuery ? "100%" : "22rem",
                      },
                    })}
                    label="Card Type:"
                    value={inputValues["payment_info"]["card_type"]}
                  />
                </Flex>
              )}
              {inputValues["payment_info"]["last4"] && (
                <Flex
                  gap={20}
                  mb={32}
                  align="center"
                  wrap={tabletMediaQuery ? "wrap" : "nowrap"}
                  justify={tabletMediaQuery ? "flex-start" : "center"}
                >
                  <TextInput
                    disabled
                    styles={(theme) => ({
                      root: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: tabletMediaQuery
                          ? "flex-start"
                          : "center",
                      },
                      label: {
                        paddingRight: 20,
                        fontFamily: theme.headings.fontFamily.bold,
                        fontSize: "1.2em",
                        width: 236,
                        textAlign: tabletMediaQuery ? "left" : "right",
                      },
                      input: {
                        paddingRight: 20,
                        border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                        fontSize: "1rem",
                        lineHeight: "1.25rem",
                        maxWidth: "100%",
                        width: tabletMediaQuery ? "100%" : "22rem",
                      },
                    })}
                    label="Last 4:"
                    value={inputValues["payment_info"]["last4"]}
                  />
                </Flex>
              )}
            </>
          ) : (
            <>
              {inputValues["payment_info"]["payment_type"] && (
                <Flex
                  gap={20}
                  mb={32}
                  align="center"
                  wrap={tabletMediaQuery ? "wrap" : "nowrap"}
                  justify={tabletMediaQuery ? "flex-start" : "center"}
                >
                  <TextInput
                    disabled
                    styles={(theme) => ({
                      root: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: tabletMediaQuery
                          ? "flex-start"
                          : "center",
                      },
                      label: {
                        paddingRight: 20,
                        fontFamily: theme.headings.fontFamily.bold,
                        fontSize: "1.2em",
                        width: 236,
                        textAlign: tabletMediaQuery ? "left" : "right",
                      },
                      input: {
                        paddingRight: 20,
                        border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                        fontSize: "1rem",
                        lineHeight: "1.25rem",
                        maxWidth: "100%",
                        width: tabletMediaQuery ? "100%" : "22rem",
                      },
                    })}
                    label="Payment Type:"
                    value={inputValues["payment_info"]["payment_type"]}
                  />
                </Flex>
              )}
              {inputValues["payment_info"]["verbose_type"] && (
                <Flex
                  gap={20}
                  mb={32}
                  align="center"
                  wrap={tabletMediaQuery ? "wrap" : "nowrap"}
                  justify={tabletMediaQuery ? "flex-start" : "center"}
                >
                  <TextInput
                    disabled
                    styles={(theme) => ({
                      root: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: tabletMediaQuery
                          ? "flex-start"
                          : "center",
                      },
                      label: {
                        paddingRight: 20,
                        fontFamily: theme.headings.fontFamily.bold,
                        fontSize: "1.2em",
                        width: 236,
                        textAlign: tabletMediaQuery ? "left" : "right",
                      },
                      input: {
                        paddingRight: 20,
                        border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                        fontSize: "1rem",
                        lineHeight: "1.25rem",
                        maxWidth: "100%",
                        width: tabletMediaQuery ? "100%" : "22rem",
                      },
                    })}
                    label="Verbose Type:"
                    value={inputValues["payment_info"]["verbose_type"]}
                  />
                </Flex>
              )}
            </>
          )}

          {(inputValues["billing_info"]["billed_to"] ||
            inputValues["billing_info"]["email"] ||
            inputValues["billing_info"]["vat_number"]) && (
            <>
              <Flex
                gap={20}
                mb={32}
                align="center"
                wrap={tabletMediaQuery ? "wrap" : "nowrap"}
                justify={tabletMediaQuery ? "flex-start" : "center"}
              >
                <Title
                  order={2}
                  styles={(theme) => ({
                    root: {
                      fontSize: "1.5em",
                      fontFamily: theme.headings.fontFamily.secondary,
                      minWidth: 185,
                    },
                  })}
                >
                  Billing Info
                </Title>
              </Flex>
              {inputValues["billing_info"]["billed_to"] && (
                <Flex
                  gap={20}
                  mb={32}
                  align="center"
                  wrap={tabletMediaQuery ? "wrap" : "nowrap"}
                  justify={tabletMediaQuery ? "flex-start" : "center"}
                >
                  <TextInput
                    disabled
                    styles={(theme) => ({
                      root: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: tabletMediaQuery
                          ? "flex-start"
                          : "center",
                      },
                      label: {
                        paddingRight: 20,
                        fontFamily: theme.headings.fontFamily.bold,
                        fontSize: "1.2em",
                        width: 236,
                        textAlign: tabletMediaQuery ? "left" : "right",
                      },
                      input: {
                        paddingRight: 20,
                        border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                        fontSize: "1rem",
                        lineHeight: "1.25rem",
                        maxWidth: "100%",
                        width: tabletMediaQuery ? "100%" : "22rem",
                      },
                    })}
                    label="Billed To:"
                    value={inputValues["billing_info"]["billed_to"]}
                  />
                </Flex>
              )}
              {inputValues["billing_info"]["email"] && (
                <Flex
                  gap={20}
                  mb={32}
                  align="center"
                  wrap={tabletMediaQuery ? "wrap" : "nowrap"}
                  justify={tabletMediaQuery ? "flex-start" : "center"}
                >
                  <TextInput
                    disabled
                    styles={(theme) => ({
                      root: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: tabletMediaQuery
                          ? "flex-start"
                          : "center",
                      },
                      label: {
                        paddingRight: 20,
                        fontFamily: theme.headings.fontFamily.bold,
                        fontSize: "1.2em",
                        width: 236,
                        textAlign: tabletMediaQuery ? "left" : "right",
                      },
                      input: {
                        paddingRight: 20,
                        border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                        fontSize: "1rem",
                        lineHeight: "1.25rem",
                        maxWidth: "100%",
                        width: tabletMediaQuery ? "100%" : "22rem",
                      },
                    })}
                    label="Email:"
                    value={inputValues["billing_info"]["email"]}
                  />
                </Flex>
              )}
              {inputValues["billing_info"]["vat_number"] && (
                <Flex
                  gap={20}
                  mb={32}
                  align="center"
                  wrap={tabletMediaQuery ? "wrap" : "nowrap"}
                  justify={tabletMediaQuery ? "flex-start" : "center"}
                >
                  <TextInput
                    disabled
                    styles={(theme) => ({
                      root: {
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: tabletMediaQuery
                          ? "flex-start"
                          : "center",
                      },
                      label: {
                        paddingRight: 20,
                        fontFamily: theme.headings.fontFamily.bold,
                        fontSize: "1.2em",
                        width: 236,
                        textAlign: tabletMediaQuery ? "left" : "right",
                      },
                      input: {
                        paddingRight: 20,
                        border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                        fontSize: "1rem",
                        lineHeight: "1.25rem",
                        maxWidth: "100%",
                        width: tabletMediaQuery ? "100%" : "22rem",
                      },
                    })}
                    label="Vat Number:"
                    value={inputValues["billing_info"]["vat_number"]}
                  />
                </Flex>
              )}
            </>
          )}

          <Flex
            gap={20}
            mb={32}
            align="center"
            wrap={tabletMediaQuery ? "wrap" : "nowrap"}
            justify={tabletMediaQuery ? "flex-start" : "center"}
          >
            <Title
              order={2}
              styles={(theme) => ({
                root: {
                  fontSize: "1.5em",
                  fontFamily: theme.headings.fontFamily.secondary,
                  minWidth: 185,
                },
              })}
            >
              Balance Info
            </Title>
          </Flex>

          <Flex
            gap={20}
            mb={32}
            align="center"
            wrap={tabletMediaQuery ? "wrap" : "nowrap"}
            justify={tabletMediaQuery ? "flex-start" : "center"}
          >
            <TextInput
              disabled
              styles={(theme) => ({
                root: {
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: tabletMediaQuery ? "flex-start" : "center",
                },
                label: {
                  paddingRight: 20,
                  fontFamily: theme.headings.fontFamily.bold,
                  fontSize: "1.2em",
                  width: 236,
                  textAlign: tabletMediaQuery ? "left" : "right",
                },
                input: {
                  paddingRight: 20,
                  border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                  maxWidth: "100%",
                  width: tabletMediaQuery ? "100%" : "22rem",
                },
              })}
              label="Free Stamp Credits:"
              value={inputValues["user_balance_info"]["expirable_credits"]}
            />
          </Flex>

          <Flex
            gap={20}
            mb={32}
            align="center"
            wrap={tabletMediaQuery ? "wrap" : "nowrap"}
            justify={tabletMediaQuery ? "flex-start" : "center"}
          >
            <TextInput
              disabled
              styles={(theme) => ({
                root: {
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: tabletMediaQuery ? "flex-start" : "center",
                },
                label: {
                  paddingRight: 20,
                  fontFamily: theme.headings.fontFamily.bold,
                  fontSize: "1.2em",
                  width: 236,
                  textAlign: tabletMediaQuery ? "left" : "right",
                },
                input: {
                  paddingRight: 20,
                  border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                  maxWidth: "100%",
                  width: tabletMediaQuery ? "100%" : "22rem",
                },
              })}
              label="Purchased Stamp Credits:"
              value={inputValues["user_balance_info"]["paid_credits"]}
            />
          </Flex>

          <Flex
            gap={20}
            mb={32}
            align="center"
            wrap={tabletMediaQuery ? "wrap" : "nowrap"}
            justify={tabletMediaQuery ? "flex-start" : "center"}
          >
            <TextInput
              disabled
              styles={(theme) => ({
                root: {
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: tabletMediaQuery ? "flex-start" : "center",
                },
                label: {
                  paddingRight: 20,
                  fontFamily: theme.headings.fontFamily.bold,
                  fontSize: "1.2em",
                  width: 236,
                  textAlign: tabletMediaQuery ? "left" : "right",
                },
                input: {
                  paddingRight: 20,
                  border: "1px solid rgba(112, 112, 112, 0.5019607843)",
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                  maxWidth: "100%",
                  width: tabletMediaQuery ? "100%" : "22rem",
                },
              })}
              label="Total Stamp Credits:"
              value={inputValues["user_balance_info"]["total_credits"]}
            />
          </Flex>

          <Flex gap={20} align="center" justify="center">
            <Button
              type="button"
              variant="transparent"
              color={theme.customColors.black}
              styles={(theme) => ({
                root: {
                  fontSize: "1.2em",
                  textDecoration: "underline",
                  paddingLeft: "0",
                  cursor: "pointer",
                  overflow: "unset",
                  fontFamily: theme.headings.fontFamily.light,
                  opacity: "0.6",
                  minWidth: 183,
                },
                inner: {
                  display: "flex",
                  justifyContent: "flex-start",
                },
              })}
              onClick={() => {
                location.href = "/profile/payment";
              }}
            >
              Change Plan
            </Button>
          </Flex>

          <Flex gap={20} align="center" justify="center">
            <Button
              className="billing-history-button"
              type="button"
              variant="transparent"
              color="#000"
              styles={(theme) => ({
                root: {
                  fontSize: "1.2em",
                  textDecoration: "underline",
                  paddingLeft: "0",
                  cursor: "pointer",
                  overflow: "unset",
                  fontFamily: theme.headings.fontFamily.primary,
                  minWidth: 183,
                },
                inner: {
                  display: "flex",
                  justifyContent: "flex-start",
                },
              })}
              onClick={() => handleClickBillingHistory()}
            >
              Billing History
            </Button>
          </Flex>

          {inputValues["subscription_info"]["is_subscription_active"] && (
            <>
              <Flex gap={20} align="center" justify="center">
                <Button
                  type="button"
                  variant="transparent"
                  styles={(theme) => ({
                    root: {
                      fontSize: "1.2em",
                      textDecoration: "underline",
                      paddingLeft: "0",
                      cursor: "pointer",
                      overflow: "unset",
                      color: theme.customColors.red,
                      fontFamily: theme.headings.fontFamily.light,
                      minWidth: 183,
                    },
                    inner: {
                      display: "flex",
                      justifyContent: "flex-start",
                    },
                  })}
                  onClick={() => handleClickShowCancel()}
                >
                  Cancel Subscription
                </Button>
              </Flex>

              {canclelShow && (
                <>
                  <Flex gap={20} mb={32} align="center" justify="center">
                    <Button
                      type="button"
                      variant="transparent"
                      styles={(theme) => ({
                        root: {
                          fontSize: "1.2em",
                          textDecoration: "underline",
                          paddingLeft: "0",
                          cursor: "pointer",
                          overflow: "unset",
                          color: theme.customColors.blackLight,
                          fontFamily: theme.headings.fontFamily.light,
                        },
                      })}
                      onClick={() => handleClickHideCancel()}
                    >
                      No
                    </Button>
                    <Button
                      type="button"
                      variant="transparent"
                      styles={(theme) => ({
                        root: {
                          fontSize: "1.2em",
                          textDecoration: "underline",
                          paddingLeft: "0",
                          cursor: "pointer",
                          overflow: "unset",
                          color: theme.customColors.blackLight,
                          fontFamily: theme.headings.fontFamily.light,
                        },
                      })}
                      onClick={() => handleClickCancel()}
                    >
                      Yes
                    </Button>
                  </Flex>
                </>
              )}

              {canclelMessage && (
                <Flex align="center" justify="center">
                  {canclelMessage}
                </Flex>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};
Billing.propTypes = {
  timeZone: PropTypes.string.isRequired,
};
export default Billing;

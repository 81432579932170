import React from "react";
import {
  IconArrowsSort,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons-react";

export const useTableIcons = () => ({
  IconArrowsSort: (props) => (
    <IconArrowsSort {...props} size={18} stroke={1.5} />
  ),
  IconSortAscending: (props) => (
    <IconSortAscending {...props} size={18} stroke={1.5} />
  ),
  IconSortDescending: (props) => (
    <IconSortDescending {...props} size={18} stroke={1.5} />
  ),
});

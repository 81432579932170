import { URLs } from "./apis";

export const verifyHashForS3File = async (hash, setStampData) => {
  // Check if hash exists before adding
  const supportedHashAlgoMap = {};
  if (!(hash in supportedHashAlgoMap)) {
    supportedHashAlgoMap[hash] = hash;
  }
  // Get list of hashes
  const objectHashes = Object.keys(supportedHashAlgoMap || {});

  const requestData = {
    "object-hashes": objectHashes,
  };

  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  try {
    const response = await fetch(URLs.VERUFY_API_URL, {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify(requestData),
    });
    const data = await response.json();

    if (data.stamp_list) {
      const firstElement = {
        ...data.stamp_list[0],
        display_timezone: data.display_timezone,
      };
      setStampData(firstElement);
    } else {
      setStampData(null);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

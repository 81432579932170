const { DateTime } = require("luxon");

/**
 * Convert a string to a timestamp using a supported format.
 * @param {*} str
 * @returns DataTime
 */
export const stringToTimestamp = (str, collectionTimezone = undefined) => {
  console.log("Parsing string:", str);
  if (!str) {
    throw new Error("String is missing.");
  }
  if (
    collectionTimezone &&
    !/^[-+]\d{2}:\d{2}$/.test(collectionTimezone) && // Offset format
    !DateTime.local().setZone(collectionTimezone).isValid // IANA time zone
  ) {
    throw new Error("Invalid collectionTimezone.");
  }
  // Define supported formats
  const formats = [
    "yyyy-MM-dd HH:mm:ssZ", // Our Internal Custom ISO-like format, without "T" separator for date and time with timezone
    "yyyy-MM-dd HH:mm:ss", // Our Internal Custom ISO-like format, without "T" separator for date and time without timezone (defaults to collectionTimezoneOffset)
    "yyyy-MM-dd'T'HH:mm:ssZ", // ISO 8601 timestamp with timezone
    "yyyy-MM-dd'T'HH:mm:ss", // ISO 8601 timestamp without timezone (defaults to collectionTimezoneOffset)
    "yyyy-MM-dd_HH-mm-ssZ", // Dash-separated timestamp with timezone
    "yyyy-MM-dd_HH-mm-ss", // Dash-separated timestamp without timezone (defaults to collectionTimezoneOffset)
    "yyyy-MM-dd_HHmmssZ", // Dash-separated timestamp with timezone
    "yyyy-MM-dd_HHmmss", // Dash-separated timestamp without timezone (defaults to collectionTimezoneOffset)
    "yyyyMMdd'T'HHmmssZ", // Full timestamp with 'T' separator and timezone
    "yyyyMMdd_HHmmssZ", // Underscore-separated timestamp with timezone
    "yyyyMMdd_HHmmss", // Underscore-separated timestamp without timezone (defaults to collectionTimezoneOffset)
    "yyyyMMddHHmmssZ", // Compact timestamp with timezone
    "yyyyMMddHHmmss", // Compact timestamp without timezone (defaults to collectionTimezoneOffset)
    "yyyyMMddHHmm", // Compact timestamp without seconds (defaults to collectionTimezoneOffset)
    "yyyyMMddHH", // Compact timestamp without minutes and seconds (defaults to collectionTimezoneOffset)
    "yyyyMMdd", // Date-only compact format (defaults to midnight collectionTimezoneOffset)
    "yyyy-MM-dd", // Date-only ISO format (defaults to midnight collectionTimezoneOffset)
  ];

  let parsedDate = null;

  const options = { zone: "utc" };
  if (collectionTimezone) {
    if (/^[-+]\d{2}:\d{2}$/.test(collectionTimezone)) {
      options.zone = `UTC${collectionTimezone}`;
    } else {
      options.zone = collectionTimezone; // IANA time zone
    }
    options.setZone = true;
  }
  for (const format of formats) {
    parsedDate = DateTime.fromFormat(str, format, options);
    if (parsedDate.isValid) {
      break;
    }
  }

  if (!parsedDate || !parsedDate.isValid) {
    console.log("Invalid timestamp.");
    throw new Error("Invalid timestamp.");
  }

  return parsedDate;
};

import { createStyles } from "@mantine/emotion";

export const useStylesUserContent = createStyles(() => {
  return {
    userContent: {
      fontSize: "1.1em",
      a: {
        textDecoration: "underline",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "ul, ol": {
        paddingLeft: "1em",
      },
    },
  };
});

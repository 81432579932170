import { createStyles } from "@mantine/emotion";

export const useStylesHeader = createStyles((theme) => ({
  header: {
    borderBottom: theme.customColors.headerBorder,
    ".rb-header-desktop": {
      [`@media (max-width: 39.99em)`]: {
        display: "none",
      },
    },
    ".rb-header-mobile": {
      display: "none",
      [`@media (max-width: 39.99em)`]: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      },
    },
    ".header-box": {
      paddingTop: "1.6em",
      paddingBottom: "1.1em",
      [`@media (max-width: 39.99em)`]: {
        paddingTop: "1.1em",
      },
    },
  },
  menuButton: {
    cursor: "pointer",
    color: theme.customColors.headerArrowIcon,
    '&[aria-expanded="true"]': {
      transform: "rotate(180deg)",
    },
  },
  menuButtonUser: {
    cursor: "pointer",
    ".user-menu-arrow": {
      color: theme.customColors.headerArrowIcon,
    },
    '&[aria-expanded="true"] .user-menu-arrow': {
      transform: "rotate(180deg)",
      display: "inline-block",
    },
    ".user-menu-name": {
      fontSize: "1em",
      fontFamily: theme.headings.fontFamily.primary,
    },
  },
  menu: {
    marginTop: "1.1em",
  },
  quotaLimitButton: {
    textDecoration: "underline",
    ":hover": {
      textDecoration: "none",
    },
  },
  quotaLimit: {
    padding: "1.5em",
    ".quotaStrong": {
      fontFamily: theme.headings.fontFamily.bold,
    },
    ".mantine-Divider-root": {
      borderColor: theme.customColors.black,
      marginBottom: "0.5em",
    },
  },
  headerLeft: {
    gap: "2em",
    [`@media (max-width: 64em)`]: {
      gap: "1em",
    },
  },
  headerRight: {
    gap: "2.2em",
    [`@media (max-width: 64em)`]: {
      gap: "1.2em",
    },
  },
}));

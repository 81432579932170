import { formatTimeStamp, LONG_DISPLAY_FORMAT } from "./dateTimeUtils";
import { calculateTimeDifference } from "./resultScreen";

export const downloadJsonToCsvRecords = (
  dataArray,
  fileName,
  timeZone,
  fileType,
) => {
  console.log(dataArray);
  // Check if the data array is valid and contains data
  if (!dataArray || dataArray.length === 0) {
    console.error("No data available to generate CSV");
    return;
  }

  // Define the desired headers and their labels
  const headers = [
    { key: "csv_label", label: "Archive file name" },
    { key: "csv_cid", label: "Content ID" },
    { key: "csv_timestamp", label: `Archive timestamp (${timeZone})` },
    { key: "timestamp", label: `Blockchain timestamp (${timeZone})` },
    { key: "time_diff", label: "Time diff vs blockchain record" },
    { key: "user", label: "Username" },
    { key: "user_id", label: "Blockchain address" },
    { key: "collection_name", label: "Collection name" },
    { key: "collection_hash", label: "Collection ID" },
    { key: "vbase_stamp_link", label: "vBase stamp link" },
    { key: "blockExplorerUrl", label: "3rd party receipt link" },
  ];

  // Remove column file name if it is Long CSV
  const filteredHeaders = headers.filter((header) => {
    if (
      fileType !== "application/zip" &&
      header.label === "Archive file name"
    ) {
      return false;
    }
    return true;
  });

  // Initialize CSV headers
  let csvContent =
    filteredHeaders.map((header) => header.label).join(",") + "\n";

  // Sort the data array by timestamp
  const sortedData = dataArray.sort((a, b) => {
    const timestampA = new Date(
      a["csv_timestamp"] || a["timestamp"] || 0,
    ).getTime();
    const timestampB = new Date(
      b["csv_timestamp"] || b["timestamp"] || 0,
    ).getTime();
    return timestampA - timestampB; // Ascending order
  });

  // Add rows to the CSV content
  sortedData.forEach((item) => {
    // Format csv_timestamp and timestamp using formatTimeStamp
    const formattedCsvTimestamp = formatTimeStamp(
      item["csv_timestamp"],
      timeZone,
      LONG_DISPLAY_FORMAT,
    );
    const formattedBlockchainTimestamp = formatTimeStamp(
      item["timestamp"],
      timeZone,
      LONG_DISPLAY_FORMAT,
    );

    // Create vBase stamp link (example logic, adjust as needed)
    const vbaseStampLink = `app.vbase.com/verify/?cid=${item["csv_cid"]}`;

    const tRdPartyReceiptLink = `https://sepolia.arbiscan.io/tx/${item["transactionHash"]}`;

    const row = filteredHeaders.map((header) => {
      let value;
      switch (header.key) {
        case "csv_timestamp":
          value = formattedCsvTimestamp; // Use formatted csv_timestamp
          break;
        case "timestamp":
          value = formattedBlockchainTimestamp; // Use formatted blockchain timestamp
          break;
        case "time_diff":
          value = calculateTimeDifference(
            item["timestamp"],
            item["csv_timestamp"],
          ); // Format time difference
          break;
        case "vbase_stamp_link":
          value = vbaseStampLink; // Use the generated link
          break;

        case "blockExplorerUrl":
          value = tRdPartyReceiptLink;
          break;

        default:
          value = item[header.key] || ""; // Use the value from the item
      }
      // Escape double quotes and wrap in quotes if the value contains a comma
      return typeof value === "string" && value.includes(",")
        ? `"${value.replace(/"/g, '""')}"`
        : value;
    });
    csvContent += row.join(",") + "\n";
  });

  // Create a Blob object for the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  // Create a temporary anchor element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName); // Set the filename for the download
  document.body.appendChild(link);
  link.click(); // Programmatically click the link to start the download

  // Remove the temporary link from the DOM after download
  document.body.removeChild(link);
};

import { URLs, fetchUserData } from "./apis";
import { notifications } from "@mantine/notifications";

export const submitFormChangeUserName = async (
  values,
  field,
  form,
  setEnableName,
  setEnableAbout,
  setChangedUserName,
) => {
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  try {
    const response = await fetch(URLs.USER_INFO_SAVE_PROFILE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify({ [field]: values[field] }), // Send the specific input value
    });

    if (response.ok) {
      setEnableName(false);
      setEnableAbout(false);
      notifications.show({
        title: "User Updated",
        color: "green",
      });
      setChangedUserName(values.name);
    } else {
      if (response.status === 400) {
        response.json().then((data) => {
          Promise.resolve().then(() => {
            if (
              data.error[field] &&
              Array.isArray(data.error[field]) &&
              data.error[field][0]
            ) {
              form.setFieldError(field, data.error[field][0].message);
            } else if (data.error[field]?.message) {
              form.setFieldError(field, data.error[field].message);
            }
          });
        });
      } else {
        Promise.resolve().then(() =>
          form.setFieldError(field, response.statusText),
        );
      }
    }
  } catch (error) {
    Promise.resolve().then(() =>
      form.setFieldError(field, "Error submitting the form:", error),
    );
    console.error("Error submitting the form:", error);
    throw error;
  }
};

export const changeTimezone = async (timezone) => {
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  try {
    const response = await fetch(URLs.USER_INFO_SAVE_PROFILE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify({ display_timezone: timezone }), // Send the specific input value
    });

    if (response.ok) {
      notifications.show({
        title: "Timezone Updated",
        color: "green",
      });
    } else {
      if (response.status === 400) {
        response.json().then(() => {
          Promise.resolve().then(() =>
            notifications.show({
              title: "Timezone Error",
              color: "red",
            }),
          );
        });
      } else {
        Promise.resolve().then(() =>
          notifications.show({
            title: "Timezone Error",
            color: "red",
          }),
        );
      }
    }
  } catch (error) {
    Promise.resolve().then(() =>
      notifications.show({
        title: "Timezone Error",
        color: "red",
      }),
    );
    console.error("Error submitting the form:", error);
    throw error;
  }
};

export const changeStorage = async (storage) => {
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  try {
    const response = await fetch(URLs.USER_INFO_SAVE_PROFILE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
      body: JSON.stringify({ storage_type: storage }), // Send the specific input value
    });

    if (response.ok) {
      notifications.show({
        title: "Storage Updated",
        color: "green",
      });
    } else {
      if (response.status === 400) {
        response.json().then(() => {
          Promise.resolve().then(() =>
            notifications.show({
              title: "Storage Error",
              color: "red",
            }),
          );
        });
      } else {
        Promise.resolve().then(() =>
          notifications.show({
            title: "Storage Error",
            color: "red",
          }),
        );
      }
    }
  } catch (error) {
    Promise.resolve().then(() =>
      notifications.show({
        title: "Timezone Error",
        color: "red",
      }),
    );
    console.error("Error submitting the form:", error);
    throw error;
  }
};

export const handleButtonClickUserAddressHistory = (
  setUserAddressHistoryData,
) => {
  fetchUserData(1, setUserAddressHistoryData);
};

export const handleButtonClickDeleteUser = async () => {
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;

  try {
    await fetch(URLs.USER_PROFILE_DELETE_ACCOUNT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to delete account");
        }
      })
      .then((data) => {
        alert(data.message);
        window.location.href = "/";
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error("Error delete user", error);
  }
};

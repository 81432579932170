import PropTypes from "prop-types";
import React, { useState } from "react";
import { fetchPutDescriptionCollection } from "../../utils/apis";
import { Box, Flex, Modal, Textarea } from "@mantine/core";
import CustomButton from "../mantineCustomComponets/CustomButton";

const DescriptionCollectionWindow = ({
  collectionID,
  onDescription,
  openedModalDescription,
  onOpenedModalDescription,
  form,
}) => {
  // Description Create success
  const [descriptionCreateSuccess, setDescriptionCreateSuccess] =
    useState(false);

  return (
    <Modal
      opened={openedModalDescription}
      onClose={() => onOpenedModalDescription(false)}
      centered
      size="30rem"
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          // Description of Collection
          const description = values.collectionDescription;

          await fetchPutDescriptionCollection(
            collectionID,
            description,
            onOpenedModalDescription,
            onDescription,
            form,
            setDescriptionCreateSuccess,
          );
        })}
      >
        <Textarea
          styles={() => ({
            input: {
              minHeight: "10em",
            },
          })}
          label="Description (Optional) – this information does not get put on a blockchain. It is hosted by vBase and can be edited and deleted later. "
          placeholder="Description"
          key={form.key("collectionDescription")}
          {...form.getInputProps("collectionDescription")}
        />
        {descriptionCreateSuccess ? (
          <Box
            className="collection-created-text"
            style={(theme) => ({
              fontSize: "0.93em",
              lineHeight: "0.93em",
              color: theme.customColors.green,
              fontFamily: theme.headings.fontFamily.light,
            })}
          >
            Description updated!
          </Box>
        ) : (
          ""
        )}
        <Flex justify="center" gap="20" mb={20} mt={20}>
          <CustomButton
            text="Save"
            componentClasses="buttonBlack modal-button-submit"
          ></CustomButton>
        </Flex>
      </form>
    </Modal>
  );
};

DescriptionCollectionWindow.propTypes = {
  collectionID: PropTypes.string,
  openedModalDescription: PropTypes.bool.isRequired,
  onDescription: PropTypes.func.isRequired,
  onOpenedModalDescription: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    customColors: PropTypes.shape({
      black: PropTypes.string.isRequired,
      white: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
    key: PropTypes.func.isRequired,
    getInputProps: PropTypes.func.isRequired,
  }).isRequired,
};
export default DescriptionCollectionWindow;

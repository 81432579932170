export const formatCollectionUserFound = (name) => {
  if (!name || typeof name !== "string") {
    return { formatted: "n/a", title_hover: false };
  }

  const result = {
    formatted: name,
    title_hover: false,
  };

  if (window.innerWidth < 768 && name.length > 28) {
    result.formatted = `${name.slice(0, 7)} ... ${name.slice(-6)}`;
    result.title_hover = true;
  } else if (name.length > 70) {
    result.formatted = `${name.slice(0, 40)} ... ${name.slice(-20)}`;
    result.title_hover = true;
  }

  return result;
};

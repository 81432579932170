import { createStyles } from "@mantine/emotion";
import { rem } from "@mantine/core";

export const useStylesButton = createStyles((theme) => ({
  buttonBlack: {
    backgroundColor: theme.customColors.black,
    color: theme.customColors.white,
    fontSize: "1.2em",
    border: "1px solid " + theme.customColors.black,
    "&:hover": {
      backgroundColor: theme.customColors.white,
      color: theme.customColors.black,
    },
  },
  buttonBlackHeader: {
    backgroundColor: theme.customBackgrounds.blackPrimary,
    borderRadius: "0.3em",
    border: theme.customColors.headerButtonBorder,
    fontSize: "1em",
    padding: "0.5em 1.8em",
  },
  buttonBlackHeaderMobile: {
    backgroundColor: theme.customBackgrounds.blackPrimary,
    borderRadius: "0.3em",
    border: theme.customColors.headerButtonBorder,
    fontSize: "1em",
    height: "2.2em",
    padding: "0.2em .8em",
  },
  buttonWhite: {
    backgroundColor: theme.customColors.white,
    color: theme.customColors.black,
    fontSize: "1.2em",
    border: "1px solid " + theme.customColors.black,
    "&:hover": {
      backgroundColor: theme.customColors.black,
      color: theme.customColors.white,
    },
  },
  buttonWhiteHeader: {
    borderRadius: "0.3em",
    border: theme.customColors.headerButtonBorder,
    fontSize: "1em",
    padding: "0.5em 1.8em",
    "&:hover": {
      backgroundColor: theme.customBackgrounds.blackPrimary,
    },
  },

  buttonWhiteHeaderPlan: {
    borderColor: theme.customColors.black,
  },
  buttonWhiteHeaderMobile: {
    height: "2.2em",
    padding: "0.2em .8em",
    borderRadius: "0.3em",
    border: theme.customColors.headerButtonBorder,
    fontSize: "1em",
    "&:hover": {
      backgroundColor: theme.customBackgrounds.blackPrimary,
    },
  },
  link: {
    fontSize: "1em",
    textDecoration: "underline",
    paddingLeft: "0",
    width: "auto",
    textAlign: "left",
    color: theme.customColors.blackLight,
    "&:hover": {
      textDecoration: "none",
      color: theme.customColors.blackLight,
    },
  },

  linkWidth90: {
    width: 90,
  },
  iconChevronLeft: {
    width: rem(21),
    height: rem(33),
    stroke: theme.customColors.white,
  },
  anchor: {
    borderRadius: "0.3em",
    padding: "0.5em 1em",
    "&:hover svg": {
      stroke: theme.customColors.black,
    },
  },
  link_padding_0: {
    padding: "0",
  },
  link_black: {
    color: theme.customColors.black,
  },
}));

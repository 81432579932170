import moment from "moment";

export const timeMapping = {
  0: "0 seconds",
  1: "1 second",
  10: "10 seconds",
  60: "1 minute",
  600: "10 minutes",
  3600: "1 hour",
  86400: "1 day",
  86401: "> 1 day",
};

export const parseTimeDiff = (timeDiff) => {
  const match = timeDiff.match(/([+-]?\d+)\s*(second|minute|hour|day)s?/);
  if (!match) return 0;

  const [, value, unit] = match;
  const parsedValue = parseInt(value, 10);

  switch (unit) {
    case "second":
      return parsedValue * 1000;
    case "minute":
      return parsedValue * 60 * 1000;
    case "hour":
      return parsedValue * 60 * 60 * 1000;
    case "day":
      return parsedValue * 24 * 60 * 60 * 1000;
    default:
      return 0;
  }
};

export const formatTimeDifferenceFromSeconds = (differenceInSeconds) => {
  const duration = moment.duration(differenceInSeconds, "seconds");
  const days = Math.floor(duration.asDays());
  const totalHours = duration.asHours(); // Get total hours including full days
  const hours = duration.hours(); // Remaining hours after full days
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (days >= 1) {
    const hoursAfterDays = (totalHours - days * 24).toFixed(2);
    return `${days}d and ${hoursAfterDays}h`;
  }

  if (hours >= 1) {
    const totalMinutes = (minutes + seconds / 60).toFixed(2);
    return `${hours}h and ${totalMinutes}m`;
  }

  // <1 hour: Show minutes and seconds without decimals
  const parts = [];
  if (minutes > 0) parts.push(`${minutes}m`);
  parts.push(`${seconds}s`);
  return parts.join(" and ");
};

// Function to calculate the time difference between two timestamps
export const calculateTimeDifference = (timestamp1, timestamp2) => {
  // Convert timestamps to seconds since UNIX epoch
  const timeInSeconds1 = moment.utc(timestamp1).unix();
  const timeInSeconds2 = moment.utc(timestamp2).unix();

  // Calculate the absolute difference in seconds
  const differenceInSeconds = Math.abs(timeInSeconds1 - timeInSeconds2);

  // Format the difference using the helper function
  return formatTimeDifferenceFromSeconds(differenceInSeconds);
};

export const calculateTimeDifferenceInSeconds = (timestamp1, timestamp2) => {
  const timeInSeconds1 = moment.utc(timestamp1).unix();
  const timeInSeconds2 = moment.utc(timestamp2).unix();
  return Math.abs(timeInSeconds2 - timeInSeconds1);
};

// Utility function to parse a custom date string format into a Date object
export const parseDate = (dateString) => {
  const [date, time] = dateString.split(" | ");
  return new Date(`${date} ${time}`);
};

import React from "react";
import { motion } from "framer-motion";
import { Flex, Box, Text } from "@mantine/core";
import { useStylesUserFound } from "../utils/mantine/useStylesUserFound";
const LoadingUserFound = () => {
  const { classes } = useStylesUserFound();
  return (
    <Box className={classes.loader}>
      <Flex
        justify="center"
        style={() => ({
          height: "6rem",
        })}
      >
        <motion.span
          className="animation"
          animate={{
            rotate: 360,
          }}
          transition={{
            repeat: Infinity,
            duration: 1,
            ease: "linear",
          }}
        />
      </Flex>
      <Box>
        <Text className="text-1">Please wait, retrieving data</Text>
        <Text className="text-2">Can take 10 seconds, but usually faster!</Text>
      </Box>
    </Box>
  );
};
export default LoadingUserFound;

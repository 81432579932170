import { createStyles } from "@mantine/emotion";

export const useStylesTooltip = createStyles((theme) => ({
  tooltip: {
    color: theme.customColors.black,
    backgroundColor: theme.customBackgrounds.white,
    pointerEvents: "auto",
    border: "1px solid " + theme.customColors.greenTooltip,
    maxWidth: "100%",
    boxShadow: "2px 4px 6px " + theme.customColors.blackLight,
    "&:hover button": {
      backgroundColor: theme.customColors.greenTooltip,
    },
  },

  tooltipArrow: {
    borderLeft: "0.5px solid " + theme.customColors.greenTooltip,
    borderTop: "0.5px solid " + theme.customColors.greenTooltip,
    borderLeftColor: theme.customColors.greenTooltip,
    borderTopColor: theme.customColors.greenTooltip,
  },
  tooltipText: {
    whiteSpace: "pre-line",
    textAlign: "justify",
    paddingBottom: "0.5em",
  },
  tooltipButton: {
    border: `1px solid ${theme.customColors.blackLight}`,
    width: "1.125rem",
    height: "1.125rem",
    padding: "0",
    minWidth: "1.125rem",
    minHeight: "1.125rem",
    backgroundColor: theme.customColors.white,
    "&:hover": {
      backgroundColor: theme.customColors.greenTooltip,
    },
  },
}));

import { createStyles } from "@mantine/emotion";

export const useStylesFileManager = createStyles((theme) => ({
  fileManager: {
    ".text": {
      fontSize: "1.2em",
      fontWeight: "normal",
      textAlign: "center",
    },
    ".file-context-menu-list ul div:not(:first-of-type):not(:nth-of-type(5)), .toolbar, .file-select-all, .file-action-container div button:not(:nth-of-type(4)), .files.list .file-item .selection-checkbox, .fm-context-menu":
      {
        display: "none",
      },
    ".navigation-pane": {
      [`@media (max-width: 64em)`]: {
        display: "none",
      },
    },
    ".folders-preview": {
      [`@media (max-width: 64em)`]: {
        width: "100% !important",
      },
    },

    ".toolbar": {
      minHeight: "3.5em",
    },
    ".sb-folders-list .active-list-item:hover, .sb-folders-list .active-list-item, .files .file-item:hover, .files .file-selected, .fm-button-primary":
      {
        background: "none",
      },

    ".files.list .file-item-container.file-selected:hover, .files .file-selected, .sb-folders-list .active-list-item, .bread-crumb-container .breadcrumb .folder-name:hover":
      {
        background: "none !important",
        color: "#000",
      },
    ".rename-file, .fm-upload-file .select-files .draggable-file-input:hover, .fm-upload-file .select-files .draggable-file-input.dragging":
      {
        borderColor: theme.customColors.blackLight,
      },
    ".toolbar .icon-only:hover": {
      color: theme.customColors.blackLight,
    },
    ".sb-folder-name": {
      width: "calc(100% - 2em) !important",
    },
    ".folder-open-icon": {
      width: "1.5em",
    },
    ".bread-crumb-container .breadcrumb": {
      maxHeight: "2em",
      minHeight: "2em",
    },
  },
  key: {
    minWidth: "5em",
  },
  value: {
    fontWeight: "bold",
  },
  filePreviewWrap: {
    ".mantine-Paper-root": {
      minWidth: "45em",
      [`@media (max-width: 64em)`]: {
        minWidth: "auto",
      },
    },
    h2: {
      fontSize: "1.3em",
      fontFamily: theme.headings.fontFamily.bold,
    },
  },
  filePreview: {
    maxWidth: "calc(100% - 40px)",
  },
}));

import PropTypes from "prop-types";
import React from "react";
import Info from "./Info";
import Notification from "./Notification";
import QuotaLimit100 from "./quota/QuotaLimit100";
import CollectionSelect from "./CollectionSelect";

const StampContentId = ({
  currentScreen,
  staticBasePath,
  handleRemoveHash,
  isSmallScreen,
  isAuthenticated,
  isValid,
  objectHash,
  handleHashChange,
  handleCreateSubmit,
  isDisabled,
  buttonStampClassName,
  quotaUsed100,
  onSelectedOptionCollection,
  selectedOptionCollection,
  activateSelectCollection,
  onActivateSelectCollection,
  helpIsActive,
  selectCollections,
}) => {
  return (
    <>
      <div id="verify-method-hash__screen" className="rb-method-hash__screen">
        <div className="rb-method">
          {currentScreen === "found" && (
            <div className="rb-container">
              <Info
                textInfo="You have already stamped this file previously. You can choose another file or stamp this content ID again."
                staticBasePath={staticBasePath}
              />
            </div>
          )}
          <div className="rb-container rb-hash-text-wrap">
            <div className="rb-hash-input">
              {!isAuthenticated && <Notification />}
              {quotaUsed100 && <QuotaLimit100 />}

              <input
                type="text"
                id="object-hash"
                className={`rb-btn rb-btn-idle rb-input ${
                  isValid ? "" : "invalid"
                } ${!isAuthenticated ? "rb-btn-disabled" : ""} ${
                  quotaUsed100 ? "rb-btn-disabled" : ""
                }`}
                placeholder="Enter content ID"
                pattern="^(0x)?[0-9a-fA-F]{64}$"
                disabled={!isAuthenticated || quotaUsed100}
                value={objectHash || ""}
                onChange={handleHashChange}
              />
              {objectHash && (
                <img
                  onClick={handleRemoveHash}
                  src={`${staticBasePath}public/images/svg/rb-remove-hash.svg`}
                  alt="Remove Hash"
                />
              )}
            </div>
            {!isValid && (
              <div className="rb-invalid-text">Invalid content ID format.</div>
            )}

            {isSmallScreen && (
              <div className="rb-info rb-btn-stamp-small">
                <button
                  className={buttonStampClassName}
                  onClick={handleCreateSubmit}
                  disabled={isDisabled}
                >
                  Make a vBase Stamp!
                </button>
                {!isAuthenticated && (
                  <div className="rb-stamper-login rb-display-flex rb-justify-content-center w-100">
                    <a href="/accounts/login/">Sign In</a> to Start Stamping!
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="rb-container">
        <div className="rb-method-hash-text-wrap rb-method-hash-text-wrap-first rb-display-flex rb-align-items-center">
          <img
            src={`${staticBasePath}public/images/svg/rb-info-grey.svg`}
            alt="Info"
          />
          <div className="rb-method-hash-text">
            <span>This is an advanced feature and</span> not recommended for
            typical users.
          </div>
        </div>
        <div className="rb-method-hash-text-wrap rb-display-flex rb-align-items-center">
          <img
            src={`${staticBasePath}public/images/svg/rb-info-grey.svg`}
            alt="Info"
          />
          <div className="rb-method-hash-text">
            <span>
              Please be sure that your content ID matches a file you saved
              locally.
            </span>
          </div>
        </div>

        <div className="rb-method-hash-text-wrap rb-display-flex rb-align-items-center">
          <img
            src={`${staticBasePath}public/images/svg/rb-info-grey.svg`}
            alt="Info"
          />
          <div className="rb-method-hash-text">
            If this process fails, your content ID will not be validated.
          </div>
        </div>

        <div className="rb-method-hash-text-wrap rb-method-hash-text-wrap-last rb-display-flex rb-align-items-center">
          <img
            src={`${staticBasePath}public/images/svg/rb-info-grey.svg`}
            alt="Info"
          />
          <div className="rb-method-hash-text">
            If you need help creating a robust workflow to stamp content IDs,{" "}
            <a
              href="https://www.vbase.com/contact/"
              rel="noreferrer"
              target="_blank"
            >
              please contact us
            </a>
            .
          </div>
        </div>
        {selectCollections && isAuthenticated && (
          <CollectionSelect
            activateSelectCollection={activateSelectCollection}
            onActivateSelectCollection={onActivateSelectCollection}
            onActivateSelectBCollection={onActivateSelectCollection}
            selectCollections={selectCollections}
            onSelectedOptionCollection={onSelectedOptionCollection}
            selectedOptionCollection={selectedOptionCollection}
            helpIsActive={helpIsActive}
          />
        )}
      </div>
    </>
  );
};
StampContentId.propTypes = {
  currentScreen: PropTypes.string.isRequired,
  staticBasePath: PropTypes.string.isRequired,
  handleRemoveHash: PropTypes.func.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  objectHash: PropTypes.string,
  handleHashChange: PropTypes.func.isRequired,
  handleCreateSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  buttonStampClassName: PropTypes.string.isRequired,
  quotaUsed100: PropTypes.bool.isRequired,
  onSelectedOptionCollection: PropTypes.func.isRequired,
  selectedOptionCollection: PropTypes.object,
  activateSelectCollection: PropTypes.bool.isRequired,
  onActivateSelectCollection: PropTypes.func.isRequired,
  helpIsActive: PropTypes.bool.isRequired,
  selectCollections: PropTypes.string,
};
export default StampContentId;

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { FileManager } from "@valikwp/react-file-manager";
import { Box, Flex, Text, Title, Modal, LoadingOverlay } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import "@valikwp/react-file-manager/dist/style.css";
import { useStylesFileManager } from "../../utils/mantine/useStylesFileManager";
import { useStylesUserTabContent } from "../../utils/mantine/useStylesUserTabContent";
import CustomButton from "../mantineCustomComponets/CustomButton";
import CustomAnchor from "../mantineCustomComponets/CustomAnchor";
import { formatFileSize } from "../../utils/formatFileSize";
import { formatHexStr } from "../../utils/formatHexStr";
import DownloadPDFUserFound from "../DownloadPDFUserFound";
import {
  formatTimeStamp,
  LONG_DISPLAY_FORMAT,
} from "../../utils/collectionFound/dateTimeUtils";
import { verifyHashForS3File } from "../../utils/verifyHashForS3File";
import LogoBlockchainSvg from "../../svg/LogoBlockchainSvg";
import LogoPDFSvg from "../../svg/LogoPDFSvg";

const transformData = (backendFiles) => {
  const result = [];

  // Set to track unique directory paths
  const directories = new Set();

  // Process each file entry
  backendFiles.forEach((item) => {
    const pathParts = item.Key.split("/").filter(Boolean); // Split Key into parts
    const filePathFull = `/${pathParts.join("/")}`; // Full path to the file
    const userAddress = pathParts[0];
    pathParts.shift();

    let currentPath = "";

    // Iterate through all parts except the last one (file)
    for (let i = 0; i < pathParts.length - 1; i++) {
      currentPath += `/${pathParts[i]}`; // Build the current path

      const link =
        userAddress === pathParts[i]
          ? ""
          : `/verify/user-data/?user=${userAddress}&collection_name=${pathParts[i]}`;

      // Add the directory if it doesn't already exist in the result
      if (!directories.has(currentPath)) {
        const directory = {
          name: pathParts[i],
          isCollection: i === 0,
          isDirectory: true,
          path: currentPath,
          link: link,
        };

        result.push(directory);
        directories.add(currentPath); // Add the path to the set
      }
    }

    // Add the file

    const filePath = `/${pathParts.map((part) => part).join("/")}`; // Full path to the file
    const fileName = pathParts[pathParts.length - 1];
    const fileParts = fileName.split(".");
    const fileExtension = fileParts.length > 1 ? fileParts.pop() : "";
    const file = {
      name: fileName, // The last part is the file
      isDirectory: false,
      path: filePath,
      fullPath: filePathFull,
      updatedAt: item.LastModified,
      size: item.Size,
      type: fileExtension,
      metadata: item.Metadata,
      collectionName: pathParts.length > 1 ? pathParts[0] : "",
    };
    result.push(file);
  });

  return result;
};

const Folders = ({ timeZone, dataStorage }) => {
  const [visible, { toggle, close: closeLoader }] = useDisclosure(false);

  // Modal Preview
  const [opened, { open, close }] = useDisclosure(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [stampData, setStampData] = useState(null);

  const openModal = (file) => {
    console.log(file);

    if (file.isDirectory === false) {
      setSelectedFile(file);
      open();
      setStampData(null);
      verifyHashForS3File(file?.metadata?.object_cid, setStampData);
    }
  };

  //css classes
  const { classes: classesFileManager } = useStylesFileManager();
  const { classes: classesUserTabContent } = useStylesUserTabContent();
  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch("/storage/list/");
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);

        const data = await response.json();
        if (!Array.isArray(data.files)) {
          console.error("Error: data.files is not an array", data.files);
          return;
        }

        const transformedFiles = transformData(data.files);
        setFiles(transformedFiles);
      } catch (err) {
        console.log(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFiles();
  }, []);

  useEffect(() => {}, [files]);

  const handleDownload = async (file) => {
    try {
      const urlDownload = `/storage/download/?file_name=${encodeURIComponent(
        file.fullPath,
      )}`;
      const response = await fetch(urlDownload, { method: "GET" });
      if (!response.ok)
        throw new Error(`Failed to download file: ${file.name}`);

      const data = await response.json();

      const link = document.createElement("a");
      link.href = data.url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Download folder zip
  const handleDownloadZip = async (folder) => {
    toggle();
    try {
      const urlDownload = `/storage/download-zip/?subfolder=${encodeURIComponent(
        folder,
      )}`;
      const response = await fetch(urlDownload, { method: "GET" });

      if (!response.ok) throw new Error(`Failed to download file: ${folder}`);

      const data = await response.json();

      const link = document.createElement("a");
      link.href = data.url;
      link.download = folder;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      closeLoader();
    }
  };

  if (isLoading) {
    return (
      <Box>
        <Flex
          justify="center"
          align="center"
          styles={() => ({
            root: {
              minHeight: 400,
            },
          })}
        >
          Loading...
        </Flex>
      </Box>
    ); // Show a loading message while data is being fetched
  }
  const initialPath =
    files.length > 0 ? formatHexStr(files[0].path, 7, 4, 14) : "";

  return (
    <Box
      className={`${classesFileManager.fileManager} ${classesUserTabContent.tabBox}`}
      pos="relative"
    >
      <LoadingOverlay
        visible={visible}
        loaderProps={{ children: "Loading..." }}
      />

      {selectedFile && (
        <Modal
          className={classesFileManager.filePreviewWrap}
          opened={opened}
          onClose={close}
          title="Stamped File Details"
        >
          <Box p="1em">
            <Flex
              className={classesFileManager.item}
              gap="0.5em"
              align="center"
            >
              <Text className={classesFileManager.key}>File name: </Text>
              <Text
                className={`${classesFileManager.value} ${classesFileManager.filePreview}`}
              >
                {formatHexStr(selectedFile.name, 260, 0, 260)}
              </Text>
            </Flex>
            <Flex
              gap="0.5em"
              className={classesFileManager.item}
              align="center"
            >
              <Text className={classesFileManager.key}>File size: </Text>
              <Text
                className={`${classesFileManager.value} ${classesFileManager.filePreview}`}
              >
                {formatFileSize(selectedFile.size)}
              </Text>
            </Flex>

            {selectedFile.collectionName && (
              <Flex
                gap="0.5em"
                className={classesFileManager.item}
                align="center"
              >
                <Text className={classesFileManager.key}>
                  Collection Name:{" "}
                </Text>
                <Text
                  className={`${classesFileManager.value} ${classesFileManager.filePreview}`}
                >
                  {selectedFile.collectionName}
                </Text>
              </Flex>
            )}

            {selectedFile?.metadata?.timestamp && (
              <Flex
                gap="0.5em"
                className={classesFileManager.item}
                align="center"
              >
                <Text className={classesFileManager.key}>
                  Stamp Date ({timeZone}):{" "}
                </Text>
                <Text
                  className={`${classesFileManager.value} ${classesFileManager.filePreview}`}
                >
                  {formatTimeStamp(
                    selectedFile?.metadata?.timestamp,
                    timeZone,
                    LONG_DISPLAY_FORMAT,
                  )}
                </Text>
              </Flex>
            )}
            <Box>
              <CustomButton
                text="Download File"
                componentClasses="link"
                variant="transparent"
                onClick={() => handleDownload(selectedFile)}
              ></CustomButton>
            </Box>
            {selectedFile.collectionName && (
              <Box>
                <CustomButton
                  text="Download Collection"
                  componentClasses="link"
                  variant="transparent"
                  onClick={() => handleDownloadZip(selectedFile.collectionName)}
                ></CustomButton>
              </Box>
            )}

            <Flex gap="1em">
              {selectedFile?.metadata?.object_cid && (
                <>
                  <Text className={classesFileManager.key}>
                    Stamp Receipts:{" "}
                  </Text>
                  <CustomAnchor
                    text=""
                    componentClasses=""
                    title="vBase Stamp Verification"
                    href={`/verify/?cid=${selectedFile?.metadata?.object_cid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    LogoVBSvgEnable="true"
                  ></CustomAnchor>
                </>
              )}

              {stampData ? (
                <>
                  <CustomAnchor
                    text=""
                    componentClasses=""
                    title="Public Blockchain Record"
                    href={`${stampData.blockExplorerUrl}${stampData.transactionHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    LogoBlockchainSvgEnable="true"
                  ></CustomAnchor>
                  <DownloadPDFUserFound
                    user_id={stampData.user_id}
                    user={stampData.user}
                    collection_name={stampData.collection_name}
                    collection_hash={stampData.collection_hash}
                    objectCid={stampData.objectCid}
                    blockExplorerUrl={stampData.blockExplorerUrl}
                    transactionHash={stampData.transactionHash}
                    chainId={stampData.chainId}
                    blockchainName={stampData.blockchainName}
                    formattedDateTime={
                      formatTimeStamp(
                        stampData.timestamp,
                        stampData.display_timezone,
                        LONG_DISPLAY_FORMAT,
                      ) +
                      " " +
                      timeZone
                    }
                  />
                </>
              ) : (
                <>
                  {selectedFile?.metadata?.object_cid && (
                    <Flex gap="1em" opacity="0.5">
                      <CustomAnchor
                        text=""
                        componentClasses=""
                        title="Please wait, the link is being generated..."
                        href="#user_data_storage"
                        rel="noopener noreferrer"
                        LogoBlockchainSvgEnable="true"
                      >
                        <LogoBlockchainSvg />
                      </CustomAnchor>

                      <CustomAnchor
                        text=""
                        componentClasses=""
                        title="Please wait, the PDF is being generated..."
                        href="#user_data_storage"
                        rel="noopener noreferrer"
                        LogoPDFSvgEnable="true"
                      >
                        <LogoPDFSvg />
                      </CustomAnchor>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </Box>
        </Modal>
      )}

      <Title order={2}>Browse Stamped Data</Title>
      <Text className="text" pb="2em">
        A backup of your previously stamped data, organized by Collection
      </Text>
      {files.length > 0 ? (
        <>
          <FileManager
            files={files}
            enableFilePreview={false}
            onFileOpen={openModal}
            layout="list"
            initialPath={initialPath}
            onDownload={handleDownload}
            onDownloadZip={handleDownloadZip}
            timeZone={timeZone}
          />
          <Text className="text" pt="2em">
            Files you stamp via the web app are {dataStorage !== "s3" && "not"}{" "}
            currently stored. To change this setting,{" "}
            <CustomAnchor
              text="click here"
              componentClasses="link"
              href="/profile/#account_settings"
            ></CustomAnchor>
            .
          </Text>
        </>
      ) : (
        <Flex justify="center" align="center" h="10em">
          <Text>No files available.</Text>
        </Flex>
      )}
    </Box>
  );
};

Folders.propTypes = {
  timeZone: PropTypes.string.isRequired,
  dataStorage: PropTypes.string,
  file: PropTypes.shape({
    fullPath: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default Folders;

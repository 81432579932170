import React from "react";
import { Flex, Text } from "@mantine/core";

export const useDataToleranceNew = (timeCollections, matchedRecords) => {
  if (timeCollections && typeof timeCollections === "object") {
    const matchedLength = matchedRecords.length;

    console.log(timeCollections);
    return [
      {
        static_text: "# of Records",
        time0s: timeCollections["0"] || 0,
        "time1-10s": timeCollections["10"] || 0,
        "10s-1m": timeCollections["60"] || 0,
        "1m-10m": timeCollections["600"] || 0,
        "10m-1h": timeCollections["3600"] || 0,
        "1h-1d": timeCollections["86400"] || 0,
        ">1d": timeCollections["86401"] || 0,
        total: <Text size="1.2em">{matchedLength}</Text>,
      },
    ];
  }

  return null;
};

export const useColumnsToleranceNew = (theme) => [
  {
    accessorKey: "static_text",
    header: "",
    size: 50,
    mantineTableHeadCellProps: {
      style: {
        backgroundColor: theme.customBackgrounds.white,
      },
    },
    mantineTableBodyCellProps: {
      style: {
        backgroundColor: theme.customBackgrounds.table,

        paddingLeft: "1.2em",
        paddingRight: "1.2em",
        paddingTop: "0",
        paddingBottom: "0",
        fontSize: "1.2em",
      },
    },
  },
  {
    accessorKey: "time0s",
    size: 50,
    header: (
      <Flex gap={5} align="center" justify="center">
        <Text size="1em">0s</Text>
      </Flex>
    ),
  },
  {
    accessorKey: "time1-10s",
    size: 50,
    header: (
      <Flex gap={5} align="center" justify="center">
        <Text size="1em">1-10s</Text>
      </Flex>
    ),
  },
  {
    accessorKey: "10s-1m",
    size: 50,
    header: (
      <Flex gap={5} align="center" justify="center">
        <Text size="1em">10s-1m</Text>
      </Flex>
    ),
  },
  {
    accessorKey: "1m-10m",
    size: 50,
    header: (
      <Flex gap={5} align="center" justify="center">
        <Text size="1em">1m-10m</Text>
      </Flex>
    ),
  },
  {
    accessorKey: "10m-1h",
    size: 50,
    header: (
      <Flex gap={5} align="center" justify="center">
        <Text size="1em">10m-1h</Text>
      </Flex>
    ),
  },
  {
    accessorKey: "1h-1d",
    size: 50,
    header: (
      <Flex gap={5} align="center" justify="center">
        <Text size="1em">1h-1d</Text>
      </Flex>
    ),
  },
  {
    accessorKey: ">1d",
    size: 50,
    header: (
      <Flex gap={5} align="center" justify="center">
        <Text size="1em">&gt; 1d</Text>
      </Flex>
    ),
  },

  {
    accessorKey: "total",
    size: 50,
    mantineTableBodyCellProps: {
      style: {
        padding: "0",
        textAlign: "center",
      },
    },
    header: (
      <Flex gap={5} align="center" justify="center">
        <Text size="1em">Total</Text>
      </Flex>
    ),
  },
];

export const useTableToleranceNew = (
  columnsTolerance,
  dataTolerance,
  theme,
) => {
  return {
    columns: columnsTolerance,
    data: dataTolerance,
    enableSorting: false,
    enableFullScreenToggle: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,

    enableColumnActions: false,

    withColumnBorders: true,

    mantineTableProps: {
      style: {
        border: "none",
        borderCollapse: "collapse",
      },
      className: "html-table html-table-custom html-table-custom-tolerance-new",
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: "none",
      className: "html-table-custom-wrap html-table-custom-wrap-tolerance",
    },
    mantineTableBodyRowProps: () => ({
      style: {
        borderBottom: theme.customColors.tableBorder,
      },
    }),
    mantineTableBodyCellProps: {
      style: {
        padding: "0.3em 1.28125em 0.25em 1.28125em",
        textAlign: "center",
        fontSize: "1.2em",
      },
    },
    mantineTableHeadRowProps: {
      style: {
        backgroundColor: theme.customBackgrounds.table,
        textAlign: "center",
      },
    },
    mantineTableHeadCellProps: {
      align: "center",
      style: {
        padding: "0.2em 1.28125em 0.1em 1.28125em",
        textAlign: "center",
        fontSize: "1.2em",
      },
    },
  };
};

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { fetchQuotaData } from "../../utils/apis";
import { Menu, Text, Flex, Divider } from "@mantine/core";
import { useStylesHeader } from "../../utils/mantine/useStylesHeader";
import moment from "moment";

const QuotaLimitHeader = ({ currentScreen, updateStampQuota }) => {
  //css Classes
  const { classes: headerClasses } = useStylesHeader();

  const formattedDateTime = (date) =>
    moment(date, "YYYY-MM-DD hh:mm:ss").format("MM/DD/YYYY");

  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [userBalanceInfo, setUserBalanceInfo] = useState("");
  const [subscriptionInfo, setSubscriptionInfo] = useState("");
  const { title: planTitle = "" } = subscriptionPlan;
  const { next_billed_at = "" } = subscriptionInfo;
  const {
    expirable_credits = 0,
    paid_credits = 0,
    total_credits = 0,
  } = userBalanceInfo;

  useEffect(() => {
    const fetchQuota = async () => {
      try {
        const { subscriptionPlan, userBalanceInfo, subscriptionInfo } =
          await fetchQuotaData(); // Use the utility function to get data
        setSubscriptionPlan(subscriptionPlan);
        setUserBalanceInfo(userBalanceInfo);
        setSubscriptionInfo(subscriptionInfo);
      } catch (error) {
        // console.log(error); // Handle any errors
      }
    };
    if (currentScreen === "created") {
      fetchQuota(); // Call the function to fetch quota data
    }
    fetchQuota(); // Call the function to fetch quota data
  }, [currentScreen, updateStampQuota]);

  return (
    <Menu
      shadow="md"
      openDelay={100}
      closeDelay={400}
      trigger="hover"
      transitionProps={{ transition: "fade-down", duration: 150 }}
      className={headerClasses.quotaLimitButton}
    >
      <Menu.Target className={headerClasses.menuButtonUser}>
        <Text className="user-menu-name">{total_credits} stamps available</Text>
      </Menu.Target>

      <Menu.Dropdown
        className={headerClasses.menu + " " + headerClasses.quotaLimit}
      >
        <Text pb="0.5em" className="quotaStrong">
          Current Stamp Balance:
        </Text>

        <Flex gap="0.5em" pb="0.5em">
          <Text component="span">Monthly Free Stamp Credits:</Text>
          <Text component="span" className="quotaStrong">
            {expirable_credits}
          </Text>
        </Flex>
        <Flex gap="0.5em" pb="0.5em">
          <Text component="span">Purchased Stamp Credits:</Text>
          <Text component="span" className="quotaStrong">
            {paid_credits}
          </Text>
        </Flex>
        <Flex gap="0.5em" pb="0.5em">
          <Text component="span">Total Stamp Credits:</Text>
          <Text component="span" className="quotaStrong">
            {total_credits}
          </Text>
        </Flex>
        <Divider />
        <Flex gap="0.5em" pb="0.5em">
          <Text component="span">Plan:</Text>
          <Text component="span" className="quotaStrong">
            {planTitle}
          </Text>
        </Flex>
        {next_billed_at && (
          <Flex gap="0.5em" pb="0.5em">
            <Text component="span">Next billing date:</Text>
            <Text component="span" className="quotaStrong">
              {formattedDateTime(next_billed_at)}
            </Text>
          </Flex>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

QuotaLimitHeader.propTypes = {
  currentScreen: PropTypes.string,
  updateStampQuota: PropTypes.bool,
};

export default QuotaLimitHeader;

import { createStyles } from "@mantine/emotion";

export const useStylesContainer = createStyles(() => ({
  container: {
    maxWidth: "73.75rem",
    width: "calc(100% - 2.5em)",
    [`@media (max-width: 39.99em)`]: {
      width: "calc(100% - 0.5em)",
    },
  },
}));

import { createStyles } from "@mantine/emotion";

export const useStylesTipTapEditor = createStyles(() => {
  return {
    editor: {
      maxWidth: "23.47em",
      [`@media (max-width: 1024px)`]: {
        maxWidth: "16.3em",
      },
      ".mantine-RichTextEditor-toolbar": {
        padding: "0.2em",
        gap: "0.2em",
        background: "none",

        ".mantine-RichTextEditor-controlsGroup": {
          background: "none",
          button: {
            minWidth: "1.5em",
            height: "1.5em",
            svg: {
              width: "1em",
              height: "1em",
            },
          },
        },
      },
      ".mantine-RichTextEditor-content": {
        background: "none",
        ".tiptap": {
          padding: "0.2em",
        },
      },
    },
  };
});

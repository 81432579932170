import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import SocialGithubSvg from "../svg/SocialGithubSvg";
import SocialLinkedinSvg from "../svg/SocialLinkedinSvg";
import SocialXSvg from "../svg/SocialXSvg";
import SocialDiscordSvg from "../svg/SocialDiscordSvg";
import { fetchQuotaData, footerForm, URLs } from "../utils/apis";
import Cookies from "js-cookie";
import {
  Anchor,
  Image,
  Text,
  Box,
  Flex,
  Container,
  TextInput,
  Modal,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useStylesFooter } from "../utils/mantine/useStylesFooter";
import { useStylesContainer } from "../utils/mantine/useStylesContainer";
import { useForm } from "@mantine/form";
import CustomButton from "./mantineCustomComponets/CustomButton";
import CustomAnchor from "./mantineCustomComponets/CustomAnchor";

const Footer = ({ staticBasePath }) => {
  //css Classes
  const { classes: containerClasses } = useStylesContainer();
  const { classes: footerClasses } = useStylesFooter();

  // Modal Quota
  const [opened, { open, close }] = useDisclosure(false);

  const [success, setSuccess] = useState(null);

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      email: "",
    },

    // functions will be used to validate values at corresponding key
    validate: {
      email: (value) => {
        if (value.length < 3) {
          return "Email must be at least 3 characters";
        }

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(value)) {
          return "Please enter a valid email address.";
        }
        return null;
      },
    },
  });

  const [quotaUsageInfo, setQuotaUsageInfo] = useState(0); // State for quota usage info

  // State to control whether the quota limit banner should be shown
  const [quotaShow, setQuotaShow] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [quotaHide, setQuotaHide] = useState(() => {
    const cookieValue = Cookies.get("quotaHide");
    return cookieValue !== undefined ? JSON.parse(cookieValue) : false;
  });

  useEffect(() => {
    const fetchQuota = async () => {
      try {
        const { quotaUsageInfo } = await fetchQuotaData(); // Use the utility function to get data

        let quotaUsageInfoPercent;

        if (quotaUsageInfo === 0) {
          quotaUsageInfoPercent = 100;
        } else if (quotaUsageInfo * 100 < 1) {
          quotaUsageInfoPercent = 99;
        } else {
          quotaUsageInfoPercent = 100 - Math.round(quotaUsageInfo * 100);
        }
        setQuotaUsageInfo(quotaUsageInfoPercent);

        // Show the banner if usage exceeds 90%, not on the payment page, and the banner wasn't hidden
        if (quotaUsageInfoPercent >= 90 && quotaHide !== true) {
          setQuotaShow(true);
          open(true);
        }
      } catch (error) {
        console.log(error); // Handle any errors
      }
    };

    fetchQuota(); // Call the function to fetch quota data
  }, []);
  return (
    <Box className={footerClasses.footer}>
      <Container className={containerClasses.container}>
        <Flex
          className={footerClasses.footerTop}
          justify="space-between"
          wrap="wrap"
          gap="1.7em"
        >
          <Box>
            <Text className="footer-title">Sign up to our mailing list</Text>

            <form
              className={footerClasses.form}
              onSubmit={form.onSubmit(async (values) => {
                await footerForm(values, setSuccess, form);
              })}
            >
              <Flex className="form-fields-wrap">
                <TextInput
                  classNames={{
                    input: "input",
                  }}
                  label=""
                  inputWrapperOrder={["label", "input"]}
                  inputContainer={(children) => (
                    <Box>
                      {children}
                      <Box className="error">{form.errors.email}</Box>
                    </Box>
                  )}
                  placeholder="Enter your email"
                  key={form.key("email")}
                  {...form.getInputProps("email")}
                />

                {success && <Text className="success">{success}</Text>}

                <CustomButton
                  text=""
                  componentClasses="submit"
                  icon="footerFormSubmit"
                />
              </Flex>
            </form>
          </Box>
          <Box>
            <Text className="footer-title">Products</Text>

            <CustomAnchor
              target="_blank"
              componentClasses="link-footer-center"
              text="validityBase for Alternative Data"
              href="https://www.vbase.com/validitybase-for-alternative-data/"
              IconChevronRightEnable={true}
            />

            <CustomAnchor
              target="_blank"
              componentClasses="link-footer-center"
              text="validityBase for Paper Trading"
              href="https://www.vbase.com/paper-trading-track-record/"
              IconChevronRightEnable={true}
            />

            <CustomAnchor
              target="_blank"
              componentClasses="link-footer-center"
              text="validityBase for Quant Research"
              href="https://www.vbase.com/quant-research/"
              IconChevronRightEnable={true}
            />

            <CustomAnchor
              target="_blank"
              componentClasses="link-footer-center"
              text="validityBase for Track Records"
              href="https://www.vbase.com/vbase-track-records/"
              IconChevronRightEnable={true}
            />

            <CustomAnchor
              target="_blank"
              componentClasses="link-footer-center"
              text="The World's Simplest Blockchain Notary"
              href="https://www.vbase.com/vbase-blockchain-notary/"
              IconChevronRightEnable={true}
            />
          </Box>
          <Box className="menu">
            <Flex gap="2em">
              <CustomAnchor
                componentClasses="link-footer-menu"
                text="About"
                href="https://www.vbase.com/about/"
              />
              <CustomAnchor
                target="_blank"
                componentClasses="link-footer-menu"
                text="Docs"
                href="https://docs.vbase.com/"
              />
              <CustomAnchor
                target="_blank"
                componentClasses="link-footer-menu"
                text="Code"
                href="https://github.com/validityBase/"
              />
              <CustomAnchor
                componentClasses="link-footer-menu"
                text="Blog"
                href="https://www.vbase.com/blog/"
              />
              <CustomAnchor
                componentClasses="link-footer-menu"
                text="Pricing"
                href="https://www.vbase.com/pricing/"
              />
              <CustomAnchor
                componentClasses="link-footer-menu"
                text="Contact"
                href="https://www.vbase.com/contact/"
              />
            </Flex>
          </Box>
        </Flex>
        <Flex
          className={footerClasses.footerCenter}
          justify="space-between"
          wrap="wrap"
          gap="1em"
        >
          <Flex gap="4em">
            <Anchor href="https://www.vbase.com">
              <Image
                w="5.133em"
                src={`${staticBasePath}public/images/svg/vb_logo-white.svg`}
                alt="validityBase"
              />
            </Anchor>

            <Box>
              <Anchor
                className="social-item"
                target="_blank"
                aria-label="github"
                href="https://github.com/validitybase"
              >
                <Box component="span">
                  <SocialGithubSvg />
                  <Box component="span"></Box>
                </Box>
              </Anchor>
              <Anchor
                className="social-item"
                target="_blank"
                aria-label="linkedin"
                href="https://www.linkedin.com/company/validitybase/"
              >
                <Box component="span">
                  <SocialLinkedinSvg />
                  <Box component="span"></Box>
                </Box>
              </Anchor>

              <Anchor
                className="social-item"
                target="_blank"
                aria-label="twitter"
                href="https://x.com/validitybase"
              >
                <Box component="span">
                  <SocialXSvg />
                  <Box component="span"></Box>
                </Box>
              </Anchor>

              <Anchor
                className="social-item"
                target="_blank"
                aria-label="discord"
                href="https://discord.gg/qjQcCNEBAh"
              >
                <Box component="span">
                  <SocialDiscordSvg />
                  <Box component="span"></Box>
                </Box>
              </Anchor>
            </Box>
          </Flex>
          <Flex gap="1em">
            <CustomAnchor
              componentClasses="link-footer-menu-second"
              text="Terms of Service"
              href="https://www.vbase.com/terms-of-service/"
            />

            <CustomAnchor
              componentClasses="link-footer-menu-second"
              text="Privacy Policy"
              href="https://www.vbase.com/privacy-policy/"
            />
          </Flex>
        </Flex>
        <Flex justify="flex-end" className={footerClasses.footerBottom}>
          <Text>© 2024 validityBase. All rights reserved.</Text>
        </Flex>
      </Container>
      {quotaShow && (
        <Modal opened={opened} onClose={close} title="" centered size="xs">
          {"You've"} used {quotaUsageInfo}% of your stamp quota. Consider
          upgrading your{" "}
          <CustomAnchor
            text="plan"
            href={URLs.PROFILE_PAYMENT_URL}
            componentClasses="link"
          />
        </Modal>
      )}
    </Box>
  );
};

Footer.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
};
export default Footer;

import PropTypes from "prop-types";
import React, { useCallback } from "react";
import Papa from "papaparse";
import CustomAnchor from "../components/mantineCustomComponets/CustomAnchor";
import { useMantineTheme } from "@mantine/core";
const DownloadCSV = ({ data, displayName, userAddress }) => {
  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  // Function to process and flatten nested data for CSV
  const prepareDataForCSV = (data, displayName, userAddress) => {
    return data.map((row) => {
      const {
        collection_name,
        timestamp,
        authenticated,
        objectCid,
        blockExplorerUrl,
        transactionHash,
        collection_hash,
        blockchainName,
        chainId,
      } = row;
      return {
        Username: displayName,
        "Collection Name": collection_name,
        Timestamp: timestamp,
        "Verified Identity?": authenticated,
        "Content ID": objectCid,
        "Blockchain Address": userAddress,
        "Public Blockchain Record": blockExplorerUrl + transactionHash,
        "Collection ID": collection_hash,
        "Blockchain Name": blockchainName,
        "Blockchain ID": chainId,
      };
    });
  };

  // Function to generate and download the CSV file
  const generateCSV = useCallback(() => {
    const processedData = prepareDataForCSV(data, displayName, userAddress); // Flatten data with user info

    const csv = Papa.unparse(processedData); // Convert to CSV format

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data_" + displayName + ".csv"; // Set the file name

    link.click();
    URL.revokeObjectURL(link.href); // Clean up
  }, [data, displayName, userAddress]);

  return (
    <CustomAnchor
      text="Download CSV"
      componentClasses="link"
      href="#"
      color={theme.customColors.black}
      onClick={(e) => {
        e.preventDefault();
        generateCSV();
      }}
    ></CustomAnchor>
  );
};

DownloadCSV.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayName: PropTypes.string.isRequired,
  userAddress: PropTypes.string.isRequired,
};

export default DownloadCSV;

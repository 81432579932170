import { createStyles } from "@mantine/emotion";

export const useStylesUserFound = createStyles((theme) => ({
  textKey: {
    fontSize: "1.2em",
  },
  textValue: {
    fontSize: "1.1em",
    fontFamily: theme.headings.fontFamily.bold,
  },

  textValueBlockChainAddressFix: {
    overflow: "auto",
    maxWidth: "100",
  },

  loader: {
    width: "100%",
    paddingTop: "0",
    paddingBottom: "0",
    position: "absolute",
    maxWidth: "none",
    marginTop: "0",
    maxHeight: "none",
    minHeight: "0",
    height: "100%",
    top: "0",
    left: "0",
    display: "flex",
    backgroundColor: theme.customColors.white,
    flexWrap: "wrap",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: "3",
    ".animation": {
      display: "block",
      width: "5rem",
      height: "5rem",
      border: "0.75rem solid " + theme.customColors.animationLoader,
      borderTop: "0.75rem solid " + theme.customColors.black,
      borderRadius: "50%",
      position: "absolute",
    },
    ".text-1": {
      fontSize: "1.5em",
    },
    ".text-2": {
      fontSize: "1em",
      fontFamily: theme.headings.fontFamily.light,
      marginTop: "0.5em",
      textAlign: "center",
    },
  },
}));

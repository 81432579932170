import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Button, rem, Tooltip, useMantineTheme } from "@mantine/core";
import { IconQuestionMark } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { useStylesTooltip } from "../../utils/mantine/useStylesTooltip";

const CustomTooltip = ({ content }) => {
  // Media query to detect if the screen width is less than 64em (1024px)
  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  const [openedTooltip, setOpenedTooltip] = useState(false);

  let closeTimeoutTooltip;

  const handleMouseEnterTooltip = () => {
    clearTimeout(closeTimeoutTooltip);
    setOpenedTooltip(true);
  };

  const handleMouseLeaveTooltip = () => {
    closeTimeoutTooltip = setTimeout(() => {
      setOpenedTooltip(false);
    }, 500);
  };

  const { classes } = useStylesTooltip();

  return (
    <Tooltip
      multiline
      w={tabletMediaQuery ? "20rem" : "30rem"}
      withArrow
      transitionProps={{ transition: "fade-up", duration: 200 }}
      classNames={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
      opened={openedTooltip}
      label={
        <Box
          style={{ whiteSpace: "pre-line" }}
          onMouseEnter={handleMouseEnterTooltip}
          onMouseLeave={handleMouseLeaveTooltip}
        >
          {content}
        </Box>
      }
    >
      <Button
        onMouseEnter={handleMouseEnterTooltip}
        onMouseLeave={handleMouseLeaveTooltip}
        classNames={{
          root: classes.tooltipButton,
        }}
        size="compact-xs"
        radius="50%"
      >
        <IconQuestionMark
          style={{
            width: rem(12),
            height: rem(12),
            stroke: theme.customBackgrounds.black,
          }}
        />
      </Button>
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  content: PropTypes.object.isRequired,
};

export default CustomTooltip;

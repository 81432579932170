import { createStyles } from "@mantine/emotion";

export const useStylesProfilePassword = createStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    [`@media (max-width: 64em)`]: {
      justifyContent: "flex-start",
    },
  },
  label: {
    paddingRight: 20,
    fontFamily: theme.headings.fontFamily.bold,
    fontSize: "1.2em",
    width: 236,
    textAlign: "right",
    [`@media (max-width: 64em)`]: {
      textAlign: "left",
    },
  },
  input: {
    paddingRight: 20,
    border: "1px solid rgba(112, 112, 112, 0.5019607843)",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    maxWidth: "100%",
    width: "22rem",
    [`@media (max-width: 64em)`]: {
      width: "100%",
    },
  },
  wrapper: {
    width: "auto",
    [`@media (max-width: 64em)`]: {
      width: "100%",
    },
  },
}));

import { createStyles } from "@mantine/emotion";

export const useStylesTabsTable = createStyles((theme) => ({
  tabsList: {
    ":before": {
      border: "none",
    },
    [`@media (max-width: 992px)`]: {
      marginBottom: "0.5em",
    },
  },
  tab: {
    border: "none",
    fontSize: "1.2em",
    opacity: "0.3",
    fontFamily: theme.headings.fontFamily.bold,
    fontWeight: "bold",
    [`@media (max-width: 992px)`]: {
      fontSize: "1em",
    },
    '&[data-active="true"]': {
      backgroundColor: theme.customBackgrounds.table,
      opacity: "1",
    },
  },
  selectWrap: {
    width: "67%",
    justifyContent: "flex-end",
    gap: "1.5em",
    [`@media (max-width: 992px)`]: {
      width: "100%",
      justifyContent: "space-between",
      gap: "0.5em",
    },
  },
  select: {
    maxWidth: "25rem",
    width: "100%",
    [`@media (max-width: 992px)`]: {
      marginBottom: "0.5em",
    },
  },
}));

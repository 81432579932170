import { createStyles } from "@mantine/emotion";

export const useStylesTable = createStyles((theme) => ({
  table: {
    ".mrt-table-container": {
      overflow: "auto",
      maxWidth: "100%",
    },
    table: {
      marginBottom: ".875em",
    },
    " .mrt-table-head-sort-button": {
      background: "none",
      svg: {
        stroke: theme.customColors.tableSortIcon,
      },
    },
  },
}));

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { URLs } from "../../utils/apis";
import {
  Box,
  CopyButton,
  Button,
  Flex,
  PasswordInput,
  Select,
  Text,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";
import moment from "moment-timezone";
import CustomButton from "../mantineCustomComponets/CustomButton";
import { changeTimezone, changeStorage } from "../../utils/userProfile";
import CustomTooltip from "../mantineCustomComponets/CustomTooltip";
import { useStylesTooltip } from "../../utils/mantine/useStylesTooltip";
import { useStylesProfileSelect } from "../../utils/mantine/useStylesProfileSelect";
import { useStylesProfilePassword } from "../../utils/mantine/useStylesProfilePassword";

const allTimezones = moment.tz.names().map((timezone) => {
  const offset = moment.tz(timezone).utcOffset();
  const currentTime = moment.tz(timezone).format("h:mmA");
  const label = ` ${timezone.padEnd(5)}   -   ${currentTime.padStart(5)}`;
  return {
    value: timezone,
    label,
    offset,
  };
});

const priorityTimezones = [
  "UTC",
  "US/Eastern",
  "US/Central",
  "US/Mountain",
  "US/Pacific",
  "Asia/Shanghai",
  "Asia/Kolkata",
  "Europe/London",
  "Europe/Paris",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
];

const sortedTimezones = allTimezones.sort((a, b) => {
  const indexA = priorityTimezones.indexOf(a.value);
  const indexB = priorityTimezones.indexOf(b.value);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) return -1;
  if (indexB !== -1) return 1;

  return a.value.localeCompare(b.value);
});

const AccountSettings = ({
  onTimeZone,
  timeZone,
  onDataStorage,
  dataStorage,
}) => {
  const tabletMediaQuery = useMediaQuery("(max-width: 64em)");

  const { classes: tooltipClasses } = useStylesTooltip();

  const { classes: profileSelectClasses } = useStylesProfileSelect();

  const { classes: profilePasswordClasses } = useStylesProfilePassword();

  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    api_key: "",
    private_key: "",
  });

  // Fetch user data from API
  useEffect(() => {
    const fetchQuotasData = async () => {
      try {
        const response = await fetch(URLs.PROFILE_API_KEYS_URL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setInputValues({
          api_key: data.api_key || "",
          private_key: data.private_key || "",
        });
        onTimeZone(data.display_timezone);
        onDataStorage(data.storage_type);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchQuotasData();
  }, []); // Empty dependency array means this effect runs once on mount

  // All user storages
  const dataStorages = [
    { value: "s3", label: "Yes" },
    { value: "", label: "No" },
  ];

  if (isLoading) {
    return (
      <Box>
        <Flex
          justify="center"
          align="center"
          styles={() => ({
            root: {
              minHeight: 400,
            },
          })}
        >
          Loading...
        </Flex>
      </Box>
    ); // Show a loading message while data is being fetched
  }

  return (
    <Box
      className="billing"
      style={() => ({
        paddingTop: 83,
        paddingBottom: 100,
      })}
    >
      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <PasswordInput
          classNames={() => ({
            root: profilePasswordClasses.root,
            label: profilePasswordClasses.label,
            input: profilePasswordClasses.input,
            wrapper: profilePasswordClasses.wrapper,
          })}
          label="API Key:"
          readOnly
          value={inputValues["api_key"]}
        />
        <CopyButton value={inputValues["api_key"]}>
          {({ copied, copy }) => (
            <Button
              variant="transparent"
              styles={(theme) => ({
                root: {
                  fontSize: "1em",
                  textDecoration: "underline",
                  paddingLeft: "0",

                  textAlign: "left",
                  color: theme.customColors.blackLight,
                },
              })}
              onClick={copy}
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          )}
        </CopyButton>
      </Flex>
      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <PasswordInput
          classNames={() => ({
            root: profilePasswordClasses.root,
            label: profilePasswordClasses.label,
            input: profilePasswordClasses.input,
            wrapper: profilePasswordClasses.wrapper,
          })}
          label="Private Key:"
          readOnly
          value={inputValues["private_key"]}
        />
        <CopyButton value={inputValues["private_key"]}>
          {({ copied, copy }) => (
            <Button
              variant="transparent"
              styles={(theme) => ({
                root: {
                  fontSize: "1em",
                  textDecoration: "underline",
                  paddingLeft: "0",

                  textAlign: "left",
                  color: theme.customColors.blackLight,
                },
              })}
              onClick={copy}
            >
              {copied ? "Copied" : "Copy"}
            </Button>
          )}
        </CopyButton>
      </Flex>

      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <Select
          label={
            <Flex align="center" gap="8">
              Display Timezone
              <CustomTooltip
                content={
                  <>
                    <Text
                      classNames={{
                        root: tooltipClasses.tooltipText,
                      }}
                    >
                      Specify the timezone that will be used to display
                      timestamps for you across the vBase application. You can
                      select timezones tied to locations (e.g. New York) or tied
                      to universal time, e.g. (GMT-5)
                    </Text>
                  </>
                }
              ></CustomTooltip>
            </Flex>
          }
          className="select-display-timezone"
          classNames={() => ({
            root: profileSelectClasses.root,
            label: profileSelectClasses.label,
            input: profileSelectClasses.input,
            wrapper: profileSelectClasses.wrapper,
          })}
          data={sortedTimezones}
          value={timeZone}
          onChange={onTimeZone}
          clearable
          searchable
        />
        <CustomButton
          text="Save"
          componentClasses="link button-display-timezone"
          type="button"
          variant="transparent"
          onClick={() => {
            changeTimezone(timeZone);
          }}
        />
      </Flex>

      <Flex
        gap={20}
        mb={32}
        align="center"
        wrap={tabletMediaQuery ? "wrap" : "nowrap"}
        justify={tabletMediaQuery ? "flex-start" : "center"}
      >
        <Select
          label={
            <Flex align="center" gap="8">
              Store Stamped Files
              <CustomTooltip
                content={
                  <>
                    <Text
                      classNames={{
                        root: tooltipClasses.tooltipText,
                      }}
                    >
                      A secure backup copy of your stamped data is needed for
                      validation.
                    </Text>
                    <Text
                      classNames={{
                        root: tooltipClasses.tooltipText,
                      }}
                    >
                      Select Yes in the dropdown menu if you’d like vBase to
                      maintain a backup of data stamped via our web application.
                    </Text>
                    <Text
                      classNames={{
                        root: tooltipClasses.tooltipText,
                      }}
                    >
                      Select No if you will maintain your own backups. In this
                      case, vBase will not see or store a copy of your data.
                    </Text>
                  </>
                }
              ></CustomTooltip>
            </Flex>
          }
          className="select-user-data-storage"
          classNames={() => ({
            root: profileSelectClasses.root,
            label: profileSelectClasses.label,
            input: profileSelectClasses.input,
            wrapper: profileSelectClasses.wrapper,
          })}
          data={dataStorages}
          value={dataStorage}
          onChange={onDataStorage}
          clearable
          searchable
        />
        <CustomButton
          text="Save"
          componentClasses="link button-user-data-storage"
          type="button"
          variant="transparent"
          onClick={() => {
            changeStorage(dataStorage);
          }}
        />
      </Flex>
    </Box>
  );
};

AccountSettings.propTypes = {
  onTimeZone: PropTypes.func.isRequired,
  timeZone: PropTypes.string,
  onDataStorage: PropTypes.func.isRequired,
  dataStorage: PropTypes.string,
};

export default AccountSettings;

import PropTypes from "prop-types";
import React from "react";
import { useEffect, useState } from "react";
import QuotaLimitHeader from "./quota/QuotaLimitHeader";
import { URLs } from "../utils/apis";
import CustomAnchor from "./mantineCustomComponets/CustomAnchor";
import {
  Anchor,
  Image,
  Menu,
  Text,
  Box,
  Flex,
  Container,
  Burger,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useStylesContainer } from "../utils/mantine/useStylesContainer";
import { useStylesHeader } from "../utils/mantine/useStylesHeader";

const Header = ({
  staticBasePath,
  djangoContext,
  currentScreen,
  changedUserName = false,
  updateStampQuota,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState("null");
  const [email, setEmail] = useState(""); // eslint-disable-line no-unused-vars

  const [shortName, setShortName] = useState("");

  const [opened, { toggle, close }] = useDisclosure();

  //css Classes
  const { classes: containerClasses } = useStylesContainer();
  const { classes: headerClasses } = useStylesHeader();
  // for staging
  const [userProfileShow] = useState(true);

  useEffect(() => {
    const { userIsAuthenticated, userEmail, userShortName } = djangoContext;

    setIsAuthenticated(userIsAuthenticated || false);
    setEmail(userEmail || "");
    setShortName(userShortName || "");
  }, []);

  return (
    <Box className={headerClasses.header}>
      <Container className={containerClasses.container}>
        <Box className="header-box">
          <Flex
            justify="space-between"
            className="rb-header-desktop"
            align="center"
          >
            <Box>
              <Flex align="center" className={headerClasses.headerLeft}>
                <Anchor href="/">
                  <Image
                    w="1.73em"
                    h="2.2em"
                    src={`${staticBasePath}public/images/svg/vb_logo.svg`}
                    alt="validityBase"
                  />
                </Anchor>
                <CustomAnchor
                  componentClasses="link link_black nav-link"
                  text="Stamp"
                  href="/stamp/"
                />
                <CustomAnchor
                  componentClasses="link link_black nav-link"
                  text="Verify"
                  href="/verify/"
                />

                <Menu shadow="md" width="15em">
                  <Menu.Target>
                    <Text className={headerClasses.menuButton}>▼</Text>
                  </Menu.Target>

                  <Menu.Dropdown className={headerClasses.menu}>
                    <Menu.Item
                      component="a"
                      className="nav-link"
                      href="https://vbase.com/"
                    >
                      Back to vbase.com
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      component="a"
                      className="nav-link"
                      href="https://github.com/validityBase/"
                    >
                      Code
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      component="a"
                      className="nav-link"
                      href="https://docs.vbase.com/"
                    >
                      Docs
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Box>
            <Box>
              {isAuthenticated ? (
                <Flex align="center" className={headerClasses.headerRight}>
                  {userProfileShow && (
                    <>
                      {window.location.pathname !== URLs.PROFILE_PAYMENT_URL &&
                        window.location.pathname !== URLs.VERIFY_URL && (
                          <QuotaLimitHeader
                            currentScreen={currentScreen}
                            updateStampQuota={updateStampQuota}
                          />
                        )}
                      <CustomAnchor
                        text="View Plans"
                        href={URLs.PROFILE_PAYMENT_URL}
                        componentClasses="buttonWhite buttonWhiteHeader buttonWhiteHeaderPlan"
                      />
                    </>
                  )}
                  <Menu shadow="md" width="15em">
                    <Menu.Target className={headerClasses.menuButtonUser}>
                      <Flex gap="1.2em">
                        <Text component="span" className="user-menu-name">
                          {changedUserName ? (
                            <>{changedUserName}</>
                          ) : (
                            <>{shortName}</>
                          )}
                        </Text>
                        <Text component="span" className="user-menu-arrow">
                          ▼
                        </Text>
                      </Flex>
                    </Menu.Target>

                    <Menu.Dropdown className={headerClasses.menu}>
                      <Menu.Item component="a" href="/profile">
                        User Profile
                      </Menu.Item>

                      <Menu.Item
                        component="a"
                        href="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper"
                      >
                        How to use vBase Stamper
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        component="a"
                        className="logout-btn"
                        href="/accounts/logout/"
                      >
                        Log Out
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Flex>
              ) : (
                <Flex gap="0.7em">
                  <CustomAnchor
                    text="Create an Account"
                    href="/accounts/signup/"
                    componentClasses="buttonBlack buttonBlackHeader"
                  />
                  <CustomAnchor
                    text="Sign In"
                    href="/accounts/login/"
                    componentClasses="buttonWhite buttonWhiteHeader"
                  />
                </Flex>
              )}
            </Box>
          </Flex>
          <Box className="rb-header-mobile">
            <Flex gap="0.5em" align="center">
              <Anchor href="/">
                <Image
                  w="1.2em"
                  h="1.535em"
                  src={`${staticBasePath}public/images/svg/vb_logo.svg`}
                  alt="validityBase"
                />
              </Anchor>

              <Menu shadow="md" width="15em">
                <Menu.Target>
                  <Burger
                    size="sm"
                    lineSize={2}
                    opened={opened}
                    onClick={toggle}
                    aria-label="Toggle navigation"
                  />
                </Menu.Target>

                <Menu.Dropdown className={headerClasses.menu}>
                  <Menu.Item component="a" className="nav-link" href="/stamp/">
                    Stamp
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item component="a" className="nav-link" href="/verify/">
                    Verify
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    component="a"
                    className="nav-link"
                    href="https://vbase.com/"
                  >
                    Back to vbase.com
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    component="a"
                    className="nav-link"
                    href="https://github.com/validityBase/"
                  >
                    Code
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    component="a"
                    className="nav-link"
                    href="https://docs.vbase.com/"
                  >
                    Docs
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Flex>
            {isAuthenticated ? (
              <>
                <CustomAnchor
                  text="View Plans"
                  href={URLs.PROFILE_PAYMENT_URL}
                  componentClasses="buttonWhite buttonWhiteHeader buttonWhiteHeaderPlan"
                />
                <Menu shadow="md" width="15em">
                  <Menu.Target className={headerClasses.menuButtonUser}>
                    <Flex gap="1.2em" onClick={close}>
                      <Text component="span" className="user-menu-name">
                        {changedUserName ? (
                          <>{changedUserName}</>
                        ) : (
                          <>{shortName}</>
                        )}
                      </Text>
                      <Text component="span" className="user-menu-arrow">
                        ▼
                      </Text>
                    </Flex>
                  </Menu.Target>

                  <Menu.Dropdown className={headerClasses.menu}>
                    <Menu.Item component="a" href="/profile">
                      User Profile
                    </Menu.Item>

                    <Menu.Item
                      component="a"
                      href="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-stamper"
                    >
                      How to use vBase Stamper
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      component="a"
                      className="logout-btn"
                      href="/accounts/logout/"
                    >
                      Log Out
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </>
            ) : (
              <Flex gap="0.7em">
                <CustomAnchor
                  text="Create an Account"
                  href="/accounts/signup/"
                  componentClasses="buttonBlack buttonBlackHeaderMobile"
                />
                <CustomAnchor
                  text="Sign In"
                  href="/accounts/login/"
                  componentClasses="buttonWhite buttonWhiteHeaderMobile"
                />
              </Flex>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Header.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  djangoContext: PropTypes.object.isRequired,
  currentScreen: PropTypes.string,
  changedUserName: PropTypes.bool,
  updateStampQuota: PropTypes.bool,
};
export default Header;

import { createStyles } from "@mantine/emotion";

export const useStylesContentBlocks = createStyles((theme) => ({
  white: {
    border: theme.customColors.tableBorder,
    backgroundColor: theme.customColors.white,
    padding: "2.25em 2.5em 6.125em 2.625em",
    position: "relative",
  },
}));

export const downloadJsonToCsvMissing = (
  dataArray,
  useCsv = false,
  fileName = "debugging_data.csv",
) => {
  console.log("useCsv", useCsv);
  // Check if the data array is valid and contains data
  if (!dataArray || dataArray.length === 0) {
    console.error("No data available to generate CSV");
    return;
  }
  const cidKey = useCsv ? "csv_cid" : "objectCid";
  const timestampKey = useCsv ? "csv_timestamp" : "timestamp";

  // Initialize CSV headers
  let csvContent = "cid,timestamp\n";
  // Generate CSV content
  dataArray.forEach((item) => {
    const cid = item[cidKey] || "";
    const timestamp = item[timestampKey] || "";
    csvContent += `${cid},${timestamp}\n`;
  });

  // Create a Blob object for the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  // Create a temporary anchor element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName); // Set the filename for the download
  document.body.appendChild(link);
  link.click(); // Programmatically click the link to start the download

  // Remove the temporary link from the DOM after download
  document.body.removeChild(link);
};

import { createStyles } from "@mantine/emotion";

export const useStylesH1 = createStyles((theme) => {
  return {
    h1: {
      fontSize: "3em",
      fontWeight: "400",
      marginTop: "0.1em",
      fontFamily: theme.headings.fontFamily.secondary,
    },
  };
});

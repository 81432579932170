export const createSubmit = async (
  objectHash,
  selectedFile,
  setLoading,
  setError,
  handleUpdateHashWorker,
  selectedOptionCollection,
  handleSelectedOptionCollection,
) => {
  setLoading(true);
  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  const formData = new FormData();
  formData.append("object-hash", objectHash);
  if (selectedOptionCollection?.id){
    formData.append("collection-hash", selectedOptionCollection?.id);
  }
  
  if (selectedFile) {
    formData.append("file", selectedFile); // Ensure the file input field has a `name="file"`
  }

  fetch("/stamp/create-stamp/", {
    method: "POST",
    headers: {
      "X-CSRFToken": csrfToken, // Do not set Content-Type, FormData handles it
    },
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        setError(data);
        setLoading(false);
      } else {
        handleUpdateHashWorker(objectHash, null, true);
        console.log(data);
      }
      handleSelectedOptionCollection("");
    })
    .catch((error) => {
      setLoading(false);
      setError(`Error: ${error.message}`);
      handleSelectedOptionCollection("");
    });
};

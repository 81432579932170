import { createStyles } from "@mantine/emotion";

export const useStylesUserInputBlock = createStyles(() => {
  return {
    userInputBlock: {
      "> div.mantine-Flex-root": {
        [`@media (max-width: 509px)`]: {
          justifyContent: "flex-start !important",
        },
      },
    },
  };
});

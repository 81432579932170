import { createStyles } from "@mantine/emotion";

export const useStylesUserTabContent = createStyles((theme) => {
  return {
    tabBox: {
      paddingTop: "5rem",
      paddingBottom: "6rem",
      h2: {
        fontFamily: theme.headings.fontFamily.secondary,
        paddingBottom: "2rem",
        textAlign: "center",
        fontSize: "3em",
        fontWeight: "normal",
      },
      h3: {
        fontSize: "1.5em",
        fontFamily: theme.headings.fontFamily.secondary,
        paddingBottom: "2rem",
        textAlign: "center",
      },
    },
  };
});

import { useState, useEffect } from "react";
import { URLs } from "./apis";
const useUserTimeZoneDataStorage = () => {
  const [dataStorage, setDataStorage] = useState("");
  const [timeZone, setTimeZone] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(URLs.PROFILE_API_KEYS_URL);
        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.json();

        setTimeZone(data.display_timezone);
        setDataStorage(data.storage_type);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserData();
  }, []);

  return { dataStorage, timeZone, setTimeZone, setDataStorage };
};

export default useUserTimeZoneDataStorage;

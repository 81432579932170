import React from "react";
import PropTypes from "prop-types";

const VerifyUserFound = ({ userData, onUserDataChange, onUserDataSubmit }) => {
  return (
    <div id="verify-method-hash__screen" className="rb-method-hash__screen">
      <div className="rb-method">
        <div className="hash-screen-container rb-container">
          <div className="rb-hash-input rb-hash-input-verify rb-hash-input-user">
            <p>Search User Activity:</p>
            <input
              type="text"
              id="user-data"
              className={`rb-btn rb-btn-idle rb-input`}
              placeholder="Enter Persistent ID, User ID or Display Name"
              value={userData}
              onChange={onUserDataChange}
            />
          </div>

          <div className="rb-hash-input">
            <button
              className="rb-btn rb-btn-click rb-btn-user"
              onClick={onUserDataSubmit}
            >
              See User Activity
              <div className="rb-btn-arrow"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
VerifyUserFound.propTypes = {
  userData: PropTypes.string.isRequired,
  onUserDataChange: PropTypes.func.isRequired,
  onUserDataSubmit: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};
export default VerifyUserFound;

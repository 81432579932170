import { createStyles } from "@mantine/emotion";

export const useStylesFooter = createStyles((theme) => ({
  footer: {
    backgroundColor: theme.customBackgrounds.blackPrimary,
    color: theme.customColors.white,
  },
  footerTop: {
    paddingTop: "3em",
    paddingBottom: "3.7em",
    borderBottom: theme.customColors.footerBorder,
    ".footer-title": {
      fontSize: "1.2em",
      lineHeight: "1.2em",
      fontFamily: theme.headings.fontFamily.secondary,
      color: theme.customColors.white,
      paddingBottom: "0.45em",
    },
    ".link-footer-center": {
      fontFamily: theme.headings.fontFamily.light,
      fontSize: "1.1em",
      paddingBottom: "0.2em",
      color: theme.customColors.white,
      minWidth: "22rem",
      display: "block",
      position: "relative",
      [`@media (max-width: 64em)`]: {
        minWidth: "20rem",
      },
      svg: {
        width: "1.27em",
        height: "1.5em",
        position: "absolute",
        right: "0",
      },
      "&:hover": {
        textDecoration: "underline",
      },
    },
    ".link-footer-menu": {
      fontFamily: theme.headings.fontFamily.light,
      fontSize: "1em",
      color: theme.customColors.white,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    ".menu": {
      [`@media (max-width: 64em)`]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
      div: {
        [`@media (max-width: 64em)`]: {
          gap: "1em !important",
        },
      },
    },
  },
  footerCenter: {
    paddingBottom: "1.2em",
    paddingTop: "1.2em",
    borderBottom: theme.customColors.footerBorder,
    ".social-item": {
      paddingLeft: "0.603125em",
      paddingRight: "0.603125em",
      textDecoration: "none",
      span: {
        display: "inline-block",
        height: "2em",
        position: "relative",
        width: "2em",
        span: {
          backgroundColor: theme.customBackgrounds.white,
          borderRadius: "50%",
          height: "calc(100% - 0.25em)",
          left: "0",
          position: "absolute",
          top: "0",
          width: "calc(100% - 0.25em);",
          zIndex: "0",
        },
        svg: {
          fill: theme.customBackgrounds.blackPrimary,
          border: theme.customColors.footerSocialBorder,
          borderRadius: "50%",
          left: "-0.125em",
          position: "relative",
          top: "-0.125em",
          zIndex: "1",
        },
      },
    },
    ".link-footer-menu-second": {
      fontSize: "0.93em",
      color: theme.customColors.white,
      fontFamily: theme.headings.fontFamily.secondary,
      "&:after": {
        color: theme.customColors.white,
        content: '"|"',
        marginLeft: "1em",
        position: "relative",
        top: "0.125em",
      },
      "&:hover": {
        textDecoration: "underline",
      },
      "&:last-child": {
        "&:after": {
          content: "none",
        },
      },
    },
  },
  form: {
    ".success": {
      color: theme.customColors.green,
      fontSize: "0.8em",
      display: "block",
      position: "absolute",
      bottom: "-1.6em",
    },
    ".error": {
      fontSize: "0.8em",
      color: theme.customColors.red,
      position: "absolute",
    },
    ".form-fields-wrap": {
      position: "relative",
    },
    ".input": {
      background: "none",
      border: theme.customColors.footerInputBorder,
      borderRadius: "4px",
      borderRight: "none",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
      color: theme.customColors.white,
      paddingLeft: "0.7em",
      paddingRight: "0.7em",
      maxWidth: "15em",
      outline: "none",
      fontSize: "0.9em",
      lineHeight: "1.1em",
      fontFamily: theme.headings.fontFamily.light,
      boxSizing: "border-box",
      height: "2em",
    },
    ".submit": {
      background: "none",
      padding: "0.6em",
      border: theme.customColors.footerInputBorder,
      borderRadius: "4px",
      borderTopLeftRadius: "0",
      borderBottomLeftRadius: "0",
      lineHeight: "1.2em",
      cursor: "pointer",
      paddingTop: "0.3em",
      paddingBottom: "0.3em",
      svg: {
        fill: "#88938f",
        paddingLeft: "0",
        fontStyle: "normal",
      },

      "&:hover": {
        backgroundColor: theme.customBackgrounds.white,

        svg: {
          fill: theme.customColors.black,
        },
      },
    },
  },
  footerBottom: {
    paddingBottom: "1.2em",
    paddingTop: "1.2em",
    fontSize: "0.93em",
    fontFamily: theme.headings.fontFamily.secondary,
  },
}));

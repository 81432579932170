import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback, useMemo } from "react";

import {
  Tabs,
  Select,
  Image,
  Flex,
  Title,
  Box,
  Text,
  useMantineTheme,
} from "@mantine/core";

import "@mantine/core/styles.css";

import { userFoundData } from "../utils/apis";

import { URLs } from "../utils/apis";
import { decodeHTML } from "../utils/decodeHTML";

//select icon
import { IconCaretDownFilled } from "@tabler/icons-react";

import LoadingUserFound from "./LoadingUserFound";
import { formatCollectionUserFound } from "../utils/formatCollectionUserFound";

import DownloadCSV from "./DownloadCSV";
import { formatHexStr } from "../utils/formatHexStr";
import { useStylesH1 } from "../utils/mantine/useStylesH1";
import { useStylesContentBlocks } from "../utils/mantine/useStylesContentBlocks";
import { useStylesTabsTable } from "../utils/mantine/useStylesTabsTable";
import {
  stampHistoryTableColumns,
  stampHistoryTableOptions,
  stampHistoryTableData,
} from "../utils/userFound/stampHistoryTable";
import {
  collectionsTableColumns,
  collectionsTableOptions,
  collectionsTableData,
} from "../utils/userFound/collectionsTable";
import {
  collectionTableColumns,
  collectionTableOptions,
  collectionTableData,
} from "../utils/userFound/collectionTable";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { useTableIcons } from "../utils/collectionFound/iconsUtil";
import { useStylesTable } from "../utils/mantine/useStylesTable";
import CustomAnchor from "./mantineCustomComponets/CustomAnchor";
import {
  formatTimeStamp,
  SHORT_DISPLAY_FORMAT,
} from "../utils/collectionFound/dateTimeUtils";
import { useStylesUserContent } from "../utils/mantine/useStylesUserContent";
import { useStylesIcons } from "../utils/mantine/useStylesIcons";
import { useStylesUserFound } from "../utils/mantine/useStylesUserFound";

const optionsFilter = ({ options, search }) => {
  const searchLower = search.toLowerCase().trim();
  return options.filter((option) =>
    option.value.toLowerCase().includes(searchLower),
  );
};

const UserFoundContent = ({ staticBasePath }) => {
  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  // css classes
  const { classes: h1Classes } = useStylesH1();
  const { classes: contentBlocksClasses } = useStylesContentBlocks();
  const { classes: tabsTableClasses } = useStylesTabsTable();
  const { classes: tableClasses } = useStylesTable();
  const { classes: userContentClasses } = useStylesUserContent();
  const { classes: iconsClasses } = useStylesIcons();
  const { classes: userFoundClasses } = useStylesUserFound();

  const selectIcon = (
    <IconCaretDownFilled width={12} color="rgba(112,112,112,0.4)" />
  );

  // fetch all user found data loaded
  const [dataLoadedUserFound, setDataLoadedUserFound] = useState(false);

  useEffect(() => {
    if (Array.isArray(inputValues?.collection_data)) {
      const selectOptionsData = inputValues.collection_data
        .filter(
          (item) =>
            (item.collection_name &&
              typeof item.collection_name === "string") ||
            (item.collection_hash && typeof item.collection_hash === "string"),
        )
        .map((item) => {
          const collectionName =
            item.collection_name === "n/a" && item.collection_hash === "n/a"
              ? "<<None>>"
              : item.collection_name === "n/a" && item.collection_hash !== "n/a"
              ? "<<Not Available>>"
              : item.collection_name;
          const collectionHash =
            item.collection_hash === "n/a" ? "<<None>>" : item.collection_hash;

          const label = collectionName
            ? formatCollectionUserFound(collectionName).formatted
            : formatHexStr(collectionHash, 6, 4, 13);

          return {
            label: label,
            value: label,
          };
        });

      setSelectOptions(selectOptionsData);
    }
  }, [inputValues?.collection_data, dataLoadedUserFound]);

  const [selectOptions, setSelectOptions] = useState([]);

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    collection_data: [],
    commitment_receipts_data: [],
    user_info_data: {},
    user_found: false,
    user_logged_in: true,
  });

  const [collectionExists, setCollectionExists] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const user = params.get("user");

    const collectionName = params.get("collection_name");
    const collectionCid = params.get("collection_cid");
    if (collectionName || collectionCid) {
      setCollectionExists(true);
    }
    const fetchUserFoundData = async () => {
      await userFoundData(
        setInputValues,
        user,
        setLoading,
        collectionName,
        collectionCid,
      );
      setDataLoadedUserFound(true);
    };

    fetchUserFoundData();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSelectChange = (value) => {
    if (value === null) {
      setSearchTerm("");
    } else {
      setSearchTerm(value);
    }
  };

  const handleSearchChange = (value) => {
    if (value === null) {
      setSearchTerm("");
    } else {
      setSearchTerm(value);
    }
  };

  const [filteredItemsNoPagination, setFilteredItemsNoPagination] = useState(
    [],
  );

  const handleFilteredItemsNoPaginationChange = useCallback((items) => {
    setFilteredItemsNoPagination(items);
  }, []);

  const handleClickFilterredbyCollection = (collection_name) => {
    setActiveTab("stamp_history");
    setSearchTerm(collection_name);
  };

  // Initialize the activeTab state from the URL hash
  const [activeTab, setActiveTab] = useState(() => {
    // Extract the hash from the URL (without the '#' symbol)
    const hash = window.location.hash.slice(1);
    return hash || "stamp_history"; // Default to 'Stamp History' tab if no hash is present
  });

  useEffect(() => {
    // Handler for hash change events
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash) {
        setActiveTab(hash); // Update the active tab based on the hash
      }
    };

    // Listen for changes to the URL hash
    window.addEventListener("hashchange", handleHashChange);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // Function to handle tab change
  const handleTabChange = (value) => {
    setActiveTab(value); // Update the active tab
    window.location.hash = value; // Update the URL hash when the tab changes
  };

  const [loading, setLoading] = useState(true);
  const userTimezone = inputValues?.user_info_data?.display_timezone;

  // Icons used within the tables, provided through a custom utility function
  const faIcons = useMemo(() => useTableIcons(), []);

  // Data preparation for the main table using memoized custom utility function
  const dataCollections = useMemo(
    () =>
      collectionsTableData(
        inputValues["collection_data"],
        staticBasePath,
        userTimezone,
        handleClickFilterredbyCollection,
      ),
    [filteredItemsNoPagination, staticBasePath, userTimezone],
  );

  // Column definitions for the main table using a utility function
  const columnsCollections = useMemo(() => collectionsTableColumns(), []);

  // Configuration options for the main table using Mantine's table library
  const tableCollections = useMantineReactTable(
    collectionsTableOptions(
      columnsCollections,
      dataCollections,
      faIcons,
      theme,
    ),
  );

  // Data preparation for the main table using memoized custom utility function
  const dataStampHistory = useMemo(
    () =>
      stampHistoryTableData(
        filteredItemsNoPagination,
        staticBasePath,
        userTimezone,
        handleClickFilterredbyCollection,
      ),
    [filteredItemsNoPagination, staticBasePath, userTimezone],
  );

  // Column definitions for the main table using a utility function
  const columnsStampHistory = useMemo(() => stampHistoryTableColumns(), []);

  // Configuration options for the main table using Mantine's table library
  const tableStampHistory = useMantineReactTable(
    stampHistoryTableOptions(
      columnsStampHistory,
      dataStampHistory,
      faIcons,
      theme,
    ),
  );

  // Data preparation for the main table using memoized custom utility function
  const dataCollection = useMemo(
    () =>
      collectionTableData(
        filteredItemsNoPagination,
        staticBasePath,
        userTimezone,
      ),
    [filteredItemsNoPagination, staticBasePath, userTimezone],
  );

  // Column definitions for the main table using a utility function
  const columnsCollection = useMemo(() => collectionTableColumns(theme), []);

  // Configuration options for the main table using Mantine's table library
  const tableCollection = useMantineReactTable(
    collectionTableOptions(columnsCollection, dataCollection, faIcons, theme),
  );

  const items = inputValues["commitment_receipts_data"];
  // Filtered items based on the search term
  const filtered = useMemo(
    () =>
      Array.isArray(items) && items.length > 0
        ? items.filter((item) => {
            const nameOrHash =
              item.collection_name && item.collection_name !== "n/a"
                ? item.collection_name
                : item.collection_hash && item.collection_hash !== "n/a"
                ? formatHexStr(item.collection_hash, 6, 4, 13)
                : "<<None>>";

            return (
              nameOrHash &&
              typeof nameOrHash === "string" &&
              formatCollectionUserFound(nameOrHash)
                .formatted.toLowerCase()
                .includes(searchTerm.toLowerCase())
            );
          })
        : [],
    [items, searchTerm],
  );

  useEffect(() => {
    handleFilteredItemsNoPaginationChange(filtered);
  }, [filtered, handleFilteredItemsNoPaginationChange]);

  return (
    <>
      {loading && <LoadingUserFound />}
      <Box pb="2.5em">
        {(inputValues?.user_info_data?.user_address && !collectionExists) ||
        (collectionExists && inputValues?.collection_data.length > 0) ? (
          <Image
            src={`${staticBasePath}public/images/svg/vb-icon-verified.svg`}
            w={64}
            alt="User Found!"
            className={iconsClasses.title}
          />
        ) : (
          <Image
            src={`${staticBasePath}public/images/svg/vb-icon-not-verified.svg`}
            w={64}
            alt="User Not Found!"
            className={iconsClasses.title}
          />
        )}
        <Flex gap="xs" align="center" justify="center">
          <Title
            classNames={{
              root: h1Classes.h1,
            }}
            order={1}
          >
            {inputValues?.user_info_data?.user_address
              ? collectionExists
                ? inputValues?.collection_data.length > 0
                  ? "Collection Detail"
                  : "Collection Not Found"
                : "User Found!"
              : "User Not Found!"}
          </Title>
        </Flex>
      </Box>
      {inputValues?.user_info_data?.user_address ? (
        <Box className={contentBlocksClasses.white}>
          <Flex justify="space-between" wrap="wrap" pb="4em">
            <Box
              style={{
                maxWidth: "100%",
              }}
            >
              {inputValues?.user_info_data?.user_persistent_id && (
                <Box pb="1em">
                  <Text
                    classNames={() => ({
                      root: userFoundClasses.textKey,
                    })}
                    component="span"
                  >
                    Persistent ID:{" "}
                  </Text>
                  <Text
                    classNames={() => ({
                      root: userFoundClasses.textValue,
                    })}
                    component="span"
                  >
                    {collectionExists ? (
                      <CustomAnchor
                        text={inputValues?.user_info_data?.user_persistent_id}
                        componentClasses="link anchor"
                        color={theme.customColors.black}
                        href={`/verify/user-data/?user=${inputValues?.user_info_data?.user_persistent_id}`}
                      ></CustomAnchor>
                    ) : (
                      inputValues?.user_info_data?.user_persistent_id
                    )}
                  </Text>
                </Box>
              )}

              {inputValues?.user_info_data?.user_name && (
                <Box pb="1em">
                  <Text
                    classNames={() => ({
                      root: userFoundClasses.textKey,
                    })}
                    component="span"
                  >
                    Display Name:{" "}
                  </Text>
                  <Text
                    classNames={() => ({
                      root: userFoundClasses.textValue,
                    })}
                    component="span"
                  >
                    {collectionExists ? (
                      <CustomAnchor
                        text={inputValues?.user_info_data?.user_name}
                        componentClasses="link anchor"
                        color={theme.customColors.black}
                        href={`/verify/user-data/?user=${inputValues?.user_info_data?.user_name}`}
                      ></CustomAnchor>
                    ) : (
                      inputValues?.user_info_data?.user_name
                    )}
                  </Text>
                </Box>
              )}

              {collectionExists && (
                <>
                  {inputValues?.collection_data?.[0]?.collection_name && (
                    <Box pb="1em">
                      <Text
                        classNames={() => ({
                          root: userFoundClasses.textKey,
                        })}
                        component="span"
                      >
                        Collection Name:{" "}
                      </Text>
                      <Text
                        classNames={() => ({
                          root: userFoundClasses.textValue,
                        })}
                        component="span"
                      >
                        {inputValues?.collection_data?.[0]?.collection_name}
                      </Text>
                    </Box>
                  )}

                  {inputValues?.collection_data?.[0]?.collection_hash && (
                    <Box pb="1em">
                      <Text
                        classNames={() => ({
                          root: userFoundClasses.textKey,
                        })}
                        component="span"
                      >
                        Collection ID:{" "}
                      </Text>
                      <Text
                        classNames={() => ({
                          root: userFoundClasses.textValue,
                        })}
                        component="span"
                      >
                        {inputValues?.collection_data?.[0]?.collection_hash}
                      </Text>
                    </Box>
                  )}
                </>
              )}

              {inputValues?.user_info_data?.description && (
                <Box pb="1em">
                  <Text
                    classNames={() => ({
                      root: userFoundClasses.textKey,
                    })}
                    component="span"
                  >
                    About:{" "}
                  </Text>

                  {collectionExists &&
                  inputValues?.collection_data?.length > 0 ? (
                    <Box
                      className={userContentClasses.userContent}
                      dangerouslySetInnerHTML={{
                        __html: decodeHTML(
                          inputValues?.collection_data?.["0"]
                            .collection_description,
                        ),
                      }}
                    />
                  ) : (
                    <Box
                      className={userContentClasses.userContent}
                      dangerouslySetInnerHTML={{
                        __html: decodeHTML(
                          inputValues?.user_info_data?.description,
                        ),
                      }}
                    />
                  )}
                </Box>
              )}
              {inputValues?.user_info_data?.user_address && (
                <Flex wrap="wrap" pb="1em" gap="0.3em">
                  <Text
                    classNames={() => ({
                      root: userFoundClasses.textKey,
                    })}
                    component="span"
                  >
                    Blockchain Address:{" "}
                  </Text>
                  <Text
                    classNames={() => ({
                      root:
                        userFoundClasses.textValue +
                        " " +
                        userFoundClasses.textValueBlockChainAddressFix,
                    })}
                    component="span"
                  >
                    {inputValues?.user_info_data?.user_address}
                  </Text>
                </Flex>
              )}

              <Flex gap="0.5em" pb="1em" wrap="wrap">
                <Text
                  classNames={() => ({
                    root: userFoundClasses.textKey,
                  })}
                  component="span"
                >
                  Current Identity Verified?{" "}
                </Text>
                {inputValues?.user_info_data?.is_verified ? (
                  <>
                    {" "}
                    <Image
                      src={`${staticBasePath}public/images/svg/verified.svg`}
                      alt="verified"
                      className={iconsClasses.userVerified}
                    />
                    <Text
                      classNames={() => ({
                        root: userFoundClasses.textValue,
                      })}
                      component="span"
                    >
                      {" "}
                      Verified{" "}
                    </Text>
                  </>
                ) : (
                  <Text
                    classNames={() => ({
                      root: userFoundClasses.textValue,
                    })}
                    component="span"
                  >
                    {" "}
                    Not Verified
                  </Text>
                )}
              </Flex>
            </Box>
            <Box w="8rem">
              {inputValues?.user_info_data?.total_stamps > 0 && (
                <>
                  <Box
                    bg={theme.customBackgrounds.infoBlock}
                    p="0.7em"
                    mb="0.5em"
                  >
                    <Text
                      styles={() => ({
                        root: {
                          textAlign: "center",
                        },
                      })}
                    >
                      Total Stamps
                    </Text>
                    <Text
                      styles={(theme) => ({
                        root: {
                          textAlign: "center",
                          fontFamily: theme.headings.fontFamily.medium,
                          fontSize: "1.5em",
                          lineHeight: "1em",
                        },
                      })}
                    >
                      {inputValues?.user_info_data?.total_stamps}
                    </Text>
                  </Box>
                  {collectionExists && inputValues?.collection_data && (
                    <>
                      <Box
                        bg={theme.customBackgrounds.infoBlock2}
                        p="0.7em"
                        mb="0.5em"
                      >
                        <Text
                          styles={() => ({
                            root: {
                              textAlign: "center",
                              color: theme.customColors.white,
                            },
                          })}
                        >
                          First Stamp
                        </Text>
                        <Text
                          styles={(theme) => ({
                            root: {
                              textAlign: "center",
                              fontFamily: theme.headings.fontFamily.medium,
                              fontSize: "1.2em",
                              lineHeight: "1em",
                              color: theme.customColors.white,
                            },
                          })}
                        >
                          {formatTimeStamp(
                            inputValues?.collection_data["0"].first_timestamp,
                            userTimezone,
                            SHORT_DISPLAY_FORMAT,
                          )}
                        </Text>
                      </Box>

                      <Box
                        bg={theme.customBackgrounds.infoBlock}
                        p="0.7em"
                        mb="0.5em"
                      >
                        <Text
                          styles={() => ({
                            root: {
                              textAlign: "center",
                            },
                          })}
                        >
                          Last Stamp
                        </Text>
                        <Text
                          styles={(theme) => ({
                            root: {
                              textAlign: "center",
                              fontFamily: theme.headings.fontFamily.medium,
                              fontSize: "1.2em",
                              lineHeight: "1em",
                            },
                          })}
                        >
                          {formatTimeStamp(
                            inputValues?.collection_data["0"].last_timestamp,
                            userTimezone,
                            SHORT_DISPLAY_FORMAT,
                          )}
                        </Text>
                      </Box>
                    </>
                  )}
                </>
              )}
              {inputValues?.user_info_data?.total_collections > 0 &&
                !collectionExists && (
                  <Box
                    bg={theme.customBackgrounds.infoBlock2}
                    p="0.7em"
                    mb="0.5em"
                  >
                    <Text
                      styles={() => ({
                        root: {
                          textAlign: "center",
                          color: theme.customColors.white,
                        },
                      })}
                    >
                      Collections
                    </Text>
                    <Text
                      styles={(theme) => ({
                        root: {
                          textAlign: "center",
                          fontFamily: theme.headings.fontFamily.medium,
                          fontSize: "1.5em",
                          lineHeight: "1em",
                          color: theme.customColors.white,
                        },
                      })}
                    >
                      {inputValues?.user_info_data?.total_collections}
                    </Text>
                  </Box>
                )}
            </Box>
          </Flex>
          {collectionExists ? (
            <Box className={tableClasses.table}>
              <MantineReactTable
                data={collectionTableData}
                columns={collectionTableColumns}
                table={tableCollection}
              />
            </Box>
          ) : (
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Flex justify="space-between" wrap="wrap">
                <Tabs.List className={tabsTableClasses.tabsList}>
                  <Tabs.Tab
                    className={tabsTableClasses.tab}
                    value="collections"
                  >
                    Collections
                  </Tabs.Tab>
                  <Tabs.Tab
                    className={tabsTableClasses.tab}
                    value="stamp_history"
                  >
                    Stamp History
                  </Tabs.Tab>
                </Tabs.List>

                <Flex
                  align="center"
                  wrap="wrap"
                  className={tabsTableClasses.selectWrap}
                >
                  {activeTab === "stamp_history" &&
                    filteredItemsNoPagination.length > 0 && (
                      <Box>
                        <DownloadCSV
                          data={filteredItemsNoPagination}
                          displayName={inputValues?.user_info_data?.user_name}
                          userAddress={
                            inputValues?.user_info_data?.user_address
                          }
                        />
                      </Box>
                    )}
                  {activeTab === "stamp_history" && selectOptions.length > 0 && (
                    <Select
                      pb={2}
                      className={tabsTableClasses.select}
                      label=""
                      placeholder="Filter by Collection"
                      data={selectOptions}
                      rightSection={selectIcon}
                      filter={(data) =>
                        optionsFilter({
                          options: data.options,
                          search: data.search,
                        })
                      }
                      searchable
                      value={searchTerm}
                      onChange={(value) => handleSelectChange(value)}
                      onSearchChange={(value) => handleSearchChange(value)}
                    />
                  )}
                </Flex>
              </Flex>

              <Tabs.Panel value="collections">
                <Box className={tableClasses.table}>
                  <MantineReactTable
                    data={collectionsTableData}
                    columns={collectionsTableColumns}
                    table={tableCollections}
                  />
                </Box>
              </Tabs.Panel>

              <Tabs.Panel value="stamp_history">
                <Box className={tableClasses.table}>
                  <MantineReactTable
                    data={stampHistoryTableData}
                    columns={stampHistoryTableColumns}
                    table={tableStampHistory}
                  />
                </Box>
              </Tabs.Panel>
            </Tabs>
          )}
        </Box>
      ) : null}
      {!collectionExists && (
        <Box mt="1.5em">
          <CustomAnchor
            text="Back to Verify"
            componentClasses="buttonBlack anchor"
            href={URLs.VERIFY_URL}
            IconChevronLeftEnable={true}
          ></CustomAnchor>
        </Box>
      )}
    </>
  );
};

UserFoundContent.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
};
export default UserFoundContent;

import PropTypes from "prop-types";
import React from "react";
import { fetchIsPinnedCollection } from "../../utils/apis";
import { Flex, Text, Modal } from "@mantine/core";
import CustomButton from "../mantineCustomComponets/CustomButton";
const IsPinnedCollectionWindow = ({
  collectionID,
  isPinned,
  setNameCollectionIsPinned,
  openedModalPinned,
  onOpenedModalPinned,
}) => {
  // Pinned Collection name
  const handleClickIsPinnedCollection = async () => {
    fetchIsPinnedCollection(
      collectionID,
      isPinned,
      onOpenedModalPinned,
      setNameCollectionIsPinned,
    );
  };

  return (
    <Modal
      opened={openedModalPinned}
      onClose={() => onOpenedModalPinned(false)}
      centered
      size="22rem"
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
    >
      <Text
        classNames={{
          root: isPinned ? "modal-text-pinned" : "modal-text-unpinned",
        }}
      >
        {isPinned
          ? "By pinning this collection, you will be able to select it on the Stamp page dropdown menu."
          : "This collection will no longer appear in the Stamp menu. You can always pin it again later."}
      </Text>

      <Text
        styles={() => ({
          root: {
            fontSize: "1.5em",
            textAlign: "center",
            marginTop: "1em",
            marginBottom: "1em",
          },
        })}
      >
        Proceed?
      </Text>

      <Flex justify="center" gap="20" mb={20}>
        <CustomButton
          text="Yes"
          componentClasses="buttonBlack modal-button-submit"
          onClick={() => handleClickIsPinnedCollection()}
        ></CustomButton>
        <CustomButton
          text="No"
          componentClasses="buttonWhite"
          onClick={() => onOpenedModalPinned(false)}
        ></CustomButton>
      </Flex>
    </Modal>
  );
};

IsPinnedCollectionWindow.propTypes = {
  collectionID: PropTypes.string,
  isPinned: PropTypes.bool.isRequired,
  setNameCollectionIsPinned: PropTypes.func.isRequired,
  openedModalPinned: PropTypes.bool.isRequired,
  onOpenedModalPinned: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    customColors: PropTypes.shape({
      black: PropTypes.string.isRequired,
      white: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
export default IsPinnedCollectionWindow;

import React from "react";
import { ActionIcon, CopyButton, Flex, rem, Tooltip } from "@mantine/core";

import { IconCheck, IconCopy } from "@tabler/icons-react";

import { formatHexStr } from "../formatHexStr";
import {
  formatTimeStamp,
  LONG_DISPLAY_FORMAT,
  SHORT_DISPLAY_FORMAT,
} from "../collectionFound/dateTimeUtils";

import { formatCollectionUserFound } from "../formatCollectionUserFound";
import { parseDate } from "../collectionFound/resultScreen";

// Function for creating data
export const collectionsTableData = (
  collectionItems,
  staticBasePath,
  userTimezone,
  handleClickFilterredbyCollection,
) => {
  if (!Array.isArray(collectionItems)) {
    return [];
  }

  return collectionItems.map((item) => ({
    collection_name: (
      <Flex
        align="center"
        gap={2}
        title={
          formatCollectionUserFound(item?.collection_name).title_hover &&
          item?.collection_name
            ? item?.collection_name
            : ""
        }
        onClick={() =>
          handleClickFilterredbyCollection(
            formatCollectionUserFound(
              item.collection_name && item.collection_name !== "n/a"
                ? item.collection_name
                : item.collection_hash && item.collection_hash !== "n/a"
                ? formatHexStr(item.collection_hash, 6, 4, 13)
                : "<<None>>",
            ).formatted,
          )
        }
      >
        {item?.collection_name && item?.collection_name !== "n/a" ? (
          <>
            {formatCollectionUserFound(item?.collection_name).formatted}

            <CopyButton value={item.collection_name} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copied" : "Copy"}
                  withArrow
                  position="right"
                >
                  <ActionIcon
                    color={copied ? "teal" : "gray"}
                    variant="subtle"
                    onClick={(event) => {
                      event.stopPropagation();
                      copy();
                    }}
                  >
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </>
        ) : item?.collection_hash && item?.collection_hash !== "n/a" ? (
          "<<Not Available>>"
        ) : (
          "<<None>>"
        )}
      </Flex>
    ),
    collection_name_sort: item?.collection_name,
    cid: (
      <Flex
        align="center"
        gap={2}
        onClick={() =>
          handleClickFilterredbyCollection(
            item.collection_hash !== "n/a"
              ? item.collection_name !== "n/a" && item.collection_name
                ? formatCollectionUserFound(item.collection_name).formatted
                : formatHexStr(item.collection_hash, 6, 4, 13)
              : "<<None>>",
          )
        }
      >
        {item?.collection_hash !== "n/a" && item?.collection_hash ? (
          <>
            {formatHexStr(item?.collection_hash, 6, 4, 13)}

            <CopyButton value={item.collection_hash} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copied" : "Copy"}
                  withArrow
                  position="right"
                >
                  <ActionIcon
                    color={copied ? "teal" : "gray"}
                    variant="subtle"
                    onClick={(event) => {
                      event.stopPropagation();
                      copy();
                    }}
                  >
                    {copied ? (
                      <IconCheck style={{ width: rem(16) }} />
                    ) : (
                      <IconCopy style={{ width: rem(16) }} />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </>
        ) : (
          "<<None>>"
        )}
      </Flex>
    ),
    cid_sort: item.collection_hash,
    count: <Flex justify="center"> {item.count}</Flex>,
    count_sort: item.count,
    first_stamp: formatTimeStamp(
      item.first_timestamp,
      userTimezone,
      SHORT_DISPLAY_FORMAT,
    ),
    first_stamp_sort: formatTimeStamp(
      item.first_timestamp,
      userTimezone,
      LONG_DISPLAY_FORMAT,
    ),
    last_stamp: formatTimeStamp(
      item.last_timestamp,
      userTimezone,
      SHORT_DISPLAY_FORMAT,
    ),
    last_stamp_sort: formatTimeStamp(
      item.last_timestamp,
      userTimezone,
      LONG_DISPLAY_FORMAT,
    ),
  }));
};

// Function for creating columns
export const collectionsTableColumns = () => [
  {
    accessorKey: "collection_name",
    header: "Collection Name",
    enableSorting: true,
    size: 400,
    sortingFn: (rowA, rowB) => {
      const collectionNameA = rowA.original.collection_name_sort || "";
      const collectionNameB = rowB.original.collection_name_sort || "";

      if (collectionNameA === collectionNameB) return 0;

      return collectionNameA.localeCompare(collectionNameB);
    },
  },
  {
    accessorKey: "cid",
    header: "Collection ID",
    size: 120,
    sortingFn: (rowA, rowB) => {
      const cidA = rowA.original.cid_sort || "";
      const cidB = rowB.original.cid_sort || "";

      if (cidA === cidB) return 0;
      if (cidA === "n/a") return 1;
      if (cidB === "n/a") return -1;

      return cidA.localeCompare(cidB);
    },
  },
  {
    accessorKey: "count",
    header: "# of Stamps",
    size: 150,
    sortingFn: (rowA, rowB) => {
      const countA = Number(rowA.original.count_sort) || 0;
      const countB = Number(rowB.original.count_sort) || 0;
      return countA - countB;
    },
  },
  {
    accessorKey: "first_stamp",
    header: "First Stamp",
    size: 150,
    sortingFn: (rowA, rowB) => {
      const timestampA = parseDate(rowA.original.first_stamp_sort);
      const timestampB = parseDate(rowB.original.first_stamp_sort);
      return timestampA - timestampB;
    },
  },
  {
    accessorKey: "last_stamp",
    header: "Last Stamp",
    size: 150,
    sortingFn: (rowA, rowB) => {
      const timestampA = parseDate(rowA.original.last_stamp_sort);
      const timestampB = parseDate(rowB.original.last_stamp_sort);
      return timestampA - timestampB;
    },
  },
];

// Function for configuring the table
export const collectionsTableOptions = (columns, data, faIcons, theme) => ({
  columns,
  data,
  icons: faIcons,
  paginationDisplayMode: "pages",
  enableFullScreenToggle: false,
  enableTopToolbar: false,
  enableBottomToolbar: true,
  enableColumnActions: false,
  initialState: { pagination: { pageIndex: 0, pageSize: 20 } },
  withColumnBorders: true,
  mantinePaginationProps: () => ({
    showRowsPerPage: false,
    color: theme.customBackgrounds.table,
    autoContrast: true,
    style: {
      paddingBottom: "1.75em",
    },
  }),
  mantineTableProps: {
    style: {
      border: "none",
      borderCollapse: "collapse",
    },
    className: "html-table-mantine",
  },
  mantinePaperProps: {
    withBorder: false,
    shadow: "none",
  },
  mantineTableBodyCellProps: {
    style: {
      padding: "0.6875em 0.9375em 0.5625em 0.9375em",
      fontSize: "1.142em",
    },
  },
  mantineTableHeadCellProps: {
    style: {
      padding: "0.55em 0.9375em 0.4375em 0.9375em",
      fontSize: "1.142em",
      fontFamily: theme.headings.fontFamily.bold,
    },
  },
  mantineTableBodyRowProps: () => ({
    style: {
      borderBottom: theme.customColors.tableBorder,
    },
  }),
  mantineTableHeadRowProps: () => ({
    style: {
      backgroundColor: theme.customBackgrounds.table,
    },
  }),
});

import { DateTime } from "luxon";

export const SHORT_DISPLAY_FORMAT = "MMM d, yyyy";

export const SHORT_NUMBERS_DISPLAY_FORMAT = "MM/d/yyyy";

export const LONG_DISPLAY_FORMAT = "MMM d, yyyy | hh:mm:ss a";

// Define supported input formats
const formats = [
  "yyyy-MM-dd HH:mm:ssZZ", // Custom format (e.g., 2025-01-22 23:16:45+00:00)
  "yyyy-MM-dd'T'HH:mm:ssZZ", // ISO-like format with "T"
];

const parseDate = (str, options) => {
  let parsedDate = DateTime.invalid("Invalid date format"); // Initialize with an invalid DateTime object
  for (const format of formats) {
    parsedDate = DateTime.fromFormat(str, format, options);
    if (parsedDate.isValid) {
      return parsedDate; // Return the valid parsed DateTime object
    }
  }
  parsedDate = DateTime.fromISO(str, options);
  if (!parsedDate.isValid) {
    throw new Error("Invalid date string.");
  }
  return parsedDate;
};

export const formatTimeStamp = (
  timestampString,
  timeZone,
  displayFormat = SHORT_DISPLAY_FORMAT,
) => {
  if (!timestampString) {
    throw new Error("String is missing.");
  }

  let result = null;
  let parsedDate = null;
  const isOffset = /^[-+]\d{2}:\d{2}$/.test(timeZone); // Offset format
  const isValidZone = DateTime.local().setZone(timeZone).isValid; // IANA time zone
  if (!isOffset && !isValidZone) {
    throw new Error("Invalid timeZone.");
  }
  const options = !isOffset
    ? { zone: timeZone, setZone: false }
    : { zone: `UTC${timeZone}` };

  parsedDate = parseDate(timestampString, options);

  result = parsedDate.toFormat(displayFormat);

  return result;
};
